import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaAngleLeft, FaFile } from 'react-icons/fa'
import { getDatasetByID} from '../API/datasets'
import { BiTransferAlt,BiDetail } from "react-icons/bi";
import { FiDatabase } from "react-icons/fi";
import { PiExportBold } from "react-icons/pi";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { RiStackLine } from "react-icons/ri";
import { ThreeCircles } from 'react-loader-spinner'
import DatasetRow from '../Components/MyDatasets/DatasetRow'
import DatasetDetailsForm from '../Components/DatasetDetailsForm'
import PaginatedDatasetRows from '../Components/MyDatasets/PaginatedDatasetRows'

export default function MyDatasets() {
  const navigate=useNavigate()
  const location=useLocation()
  const dataset_id=location.state.dataset_id
  const project_id=location.state.project_id
  console.log(dataset_id,project_id)
  const [loading,setLoading]=useState(true)
  const [active,setActive]=useState("details")
  const [data,setData]=useState(null)


  const getDatasetDetails=async()=>{
    const res=await getDatasetByID(dataset_id)
    setData(res)
    setLoading(false)
  }

  useEffect(()=>{
    getDatasetDetails()
  },[])

  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center '>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Dataset...</span></p>
    </div>)
  } 
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6 bg-emerald-50'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Dataset Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
          <h2 className='text-left text-3xl font-bold '>Manage <span className='text-emerald-700 '>Dataset</span></h2>
          <p className='text-sm font-gray-600'>View, edit and export your dataset</p>
      </div>
      <div className="w-full flex items-center justify-center p-6 ">
        {active==="details" && <button className='flex items-center px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700 rounded-l-lg'><BiDetail className='w-6 h-6 mr-2'/>Details</button>}
        {active!=="details" && <button onClick={()=>setActive("details")} className='flex items-center px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 rounded-l-lg bg-white hover:bg-emerald-100'><BiDetail className='w-6 h-6 mr-2'/>Details</button>}
        {active==="dataset" && <button className='flex items-center px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'><FiDatabase className='w-6 h-6 mr-2'/>Data</button>}
        {active!=="dataset" && <button onClick={()=>setActive("dataset")} className='flex items-center px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 bg-white hover:bg-emerald-100'><FiDatabase className='w-6 h-6 mr-2'/>Data</button>}
        {active==="export" && <button className='flex items-center px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'><PiExportBold  className='w-6 h-6 mr-2'/>Export</button>}
        {active!=="export" && <button onClick={()=>setActive("export")} className='flex items-center px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 bg-white hover:bg-emerald-100'><PiExportBold  className='w-6 h-6 mr-2'/>Export</button>}
        {active==="convert" && <button className='flex items-center px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700 rounded-r-lg'><BiTransferAlt  className='w-6 h-6 mr-2'/>Convert</button>}
        {active!=="convert" && <button onClick={()=>setActive("convert")} className='flex items-center px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 rounded-r-lg bg-white hover:bg-emerald-100'><BiTransferAlt  className='w-6 h-6 mr-2'/>Convert</button>}
      </div>
      {active==="details" && <div className='w-full max-w-7xl flex flex-col items-center p-8 md:p-12 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl text-center font-semibold text-emerald-700 mb-4'>Dataset Details</h2>
        <DatasetDetailsForm data={data}/>
      </div>}
      {active==="dataset" && <div className='w-full max-w-7xl flex flex-col items-center p-8 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Datapoints</h2>
        <div className='w-full grid grid-cols-1 grid-cols-2 gap-4 mb-4'>
          
          <button onClick={()=>navigate("/create-qa-questionset",{state:{dataset_id:dataset_id,project_id:project_id}})} className="w-full flex flex-col items-center text-lg font-semibold bg-white hover:bg-emerald-700 hover:text-white border border-emerald-700 p-6 rounded"><RiStackLine className='text-5xl mb-2'/><p>Create From Question-set</p></button>
          <button onClick={()=>navigate("/create-qa",{state:{dataset_id:dataset_id}})} className="w-full flex flex-col items-center text-lg font-semibold bg-white hover:bg-emerald-700 hover:text-white border border-emerald-700 p-6 rounded"><MdOutlineLibraryAdd className='text-5xl mb-2' /><p>Manual Add</p></button>
        </div>
        <div className='w-full p-6 border shadow rounded bg-white'>
          <h2 className='text-xl font-bold text-emerald-700'>Added Datapoints</h2>
          <p className='text-gray-500 text-sm mb-4'>Datapoints that have been added by you</p>
          {data.generator_dataset_row && <PaginatedDatasetRows data={data}/>}
        {/* {data.generator_dataset_row.map(a=>(<DatasetRow data={a}/>))} */}
        </div>
      </div>}
      {active==="export" && <div className='w-full max-w-7xl flex flex-col items-center p-8 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Export Dataset</h2>
        <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/8cBv0Lz8/11.png" className='w-16'/>
            <h3 className='text-lg font-semibold mt-2'>Export as CSV</h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/xTnMbp69/12.png" className='w-16'/>
            <h3 className='text-lg font-semibold mt-2'>Export as XLSX</h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/4NPtvv0V/13.png" className='w-16'/>
            <h3 className='text-lg font-semibold mt-2'>Export as JSON</h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/2S5W0bpJ/14.png" className='w-16'/>
            <h3 className='text-lg font-semibold mt-2'>Export as JSONL</h3>
          </motion.div>
        </div>
      </div>}
      {active==="convert" && <div className='w-full max-w-7xl  flex flex-col items-center p-8 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Convert Dataset</h2>
        <div className='w-full grid grid-cols-3 gap-4'>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/xj7QqqnQ/11.png" className='w-16'/>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>LLAMA</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/mg3WdwMZ/12.png" className='w-16'/>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>Gemma</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/Gmn1JN4S/13.png" className='w-16'/>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>Phi</span></h3>
          </motion.div>
          
        </div>
      </div>}
    </div>
  )
}
