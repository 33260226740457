import React,{useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { useAuth } from '../Hooks/AuthContext'
import {createUser, getUserWithEmail} from '../API/users'
import toast from 'react-hot-toast'
export default function ExpertProfiling() {
    const navigate=useNavigate()
    const {user}=useAuth()
    const [loading,setLoading]=useState(false)
    const [formData,setFormData]=useState({})
    const [selectedGender,setSeletedGender]=useState(null)
    const [selectedOccupation,setSeletedOccupation]=useState(null)
    const [selectedDegree,setSeletedDegree]=useState(null)
    const [selectedLlmUsage,setSeletedLlmUsage]=useState(null)
    const genderOptions=["Male","Female","Other","Rather not say"]
    const occupationOptions=["Student","Teacher","Researcher","Other"]
    const degreeOptions=["Secondary School","Higher Secondary School","Undergraduate","Masters"]
    const llmUsageOptions=["Daily","Monthly","Yearly","Never Used"]
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    const saveProfile=async()=>{
        console.log(formData)
        setLoading(true)
        const userData={
            email:user.email,
            name:formData.name,
            institution:formData.institution,
            reason:formData.reason,
            gender:selectedGender,
            occupation:selectedOccupation,
            degree:selectedDegree,
            llm_usage:selectedLlmUsage,
            user_type:"Evaluation Creator"
        }
        const res=await createUser(userData)
        console.log(res)
        setLoading(false)
       
        if(res==null){
            toast.error("Expert profile could not be created")
            return
        }
        if(res!=null){
            toast.success("Your expert profile has been updated!")
            navigate('/evaluations')
        }
        
    }
    const getUser=async()=>{
        const res= await getUserWithEmail(user.email)
        if(res){
            setFormData({...formData,["name"]: res.name,["institution"]:res.institution,["reason"]:res.reason});
            setSeletedGender(res.gender)
            setSeletedOccupation(res.occupation)
            setSeletedDegree(res.degree)
            setSeletedLlmUsage(res.llm_usage)
            console.log("USER ",res)
            //alert("User Exists Previously")
        }
    }
    useEffect(()=>{
        getUser()
    },[])
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-start'>
        <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex items-center justify-start p-4 md:p-6 bg-white'>
            <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                <FaAngleLeft/>
            </motion.button>
           <h2 className='ml-4 font-semibold text-md'>S.A.L. Human Evaluator</h2>
            
        </div>
        <div className='mt-20 w-full max-w-7xl px-12'>
            <h2 className='text-3xl font-bold text-purple-900'>Expert Profiling✍</h2>
            <p className='text-sm font-semibold text-gray-700'>Answer the following questions before you start generating answers</p>
        </div>
        <div className="space-y-4 max-w-7xl p-12 ">
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Your Name
                </label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
            
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Your Institution
                </label>
                <input
                    type="text"
                    name="institution"
                    value={formData.institution}
                    onChange={handleChange}
                    placeholder="Your Institution"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
            
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Usage Reason
                </label>
                <input
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    placeholder="Your Usage Reason"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
            
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Gender
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {genderOptions.map(a=>(
                        <button onClick={()=>setSeletedGender(a)} className={`p-4 border rounded-md shadow ${selectedGender===a && "bg-purple-900 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Occupation
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {occupationOptions.map(a=>(
                        <button onClick={()=>setSeletedOccupation(a)} className={`p-4 border rounded-md shadow ${selectedOccupation===a && "bg-purple-900 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Degree
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {degreeOptions.map(a=>(
                        <button onClick={()=>setSeletedDegree(a)} className={`p-4 border rounded-md shadow ${selectedDegree===a && "bg-purple-900 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    LLM Usage
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {llmUsageOptions.map(a=>(
                        <button onClick={()=>setSeletedLlmUsage(a)} className={`p-4 border rounded-md shadow ${selectedLlmUsage===a && "bg-purple-900 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            
            <div>
                <button onClick={saveProfile} className='px-4 py-2 rounded border border-purple-900 text-purple-900 text-md font-semibold hover:bg-purple-900 hover:text-white'>{loading ?"Saving Profile..":"Save Profile"}</button>
            </div>
                  
        </div>
    </div>
  )
}
