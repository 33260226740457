import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Hooks/AuthContext';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import ExpertProfiling from './Pages/ExpertProfiling';
import ExpertProfile from './Pages/ExpertProfile';
import CreateQA from './Pages/DatasetCreation/CreateQA';
import MyDatasets from './Pages/MyDatasets';
import ProtectedRoutes from './ProtectedRoutes'
import { Toaster } from 'react-hot-toast';
import PDFQuestionnaire from './Pages/QuestionSetCreation/PDFQuestionnaire';
import MyQuestionsets from './Pages/MyQuestionSets';
import CreateFromQuestionSet from './Pages/DatasetCreation/CreateFromQuestionSet';
import CreateFromCSV from './Pages/QuestionSetCreation/CreateFromCSV';
import CreateManual from './Pages/QuestionSetCreation/CreateManual';
import ProjectHome from './Pages/ProjectHome';
import ProjectDetails from './Pages/ProjectDetails';
import MyFinetunes from './Pages/MyFinetunes';

import Quiz from './Pages/QuestionSetCreation/Quiz/Quiz';
import Flashcards from "./Pages/QuestionSetCreation/Flashcards/FlashCards"

function App() {

  return (
    <AuthProvider>
      <Toaster/>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          
          {/*EXPERTS*/}
          <Route path="/expert-profiling" element={<ProtectedRoutes><ExpertProfiling /></ProtectedRoutes>} />
          <Route path="/expert-profile" element={<ProtectedRoutes><ExpertProfile /></ProtectedRoutes>} />

          {/*PROJECTS*/}
          <Route path="/projects" element={<ProtectedRoutes><ProjectHome /></ProtectedRoutes>} />
          <Route path="/project/:id" element={<ProtectedRoutes><ProjectDetails /></ProtectedRoutes>} />

          {/*QUESTIONSETS*/}
          <Route path="/my-questionsets" element={<ProtectedRoutes><MyQuestionsets /></ProtectedRoutes>} />
          <Route path="/my-questionset-pdf" element={<ProtectedRoutes><PDFQuestionnaire /></ProtectedRoutes>} />
          <Route path="/my-questionset-single" element={<ProtectedRoutes><CreateManual /></ProtectedRoutes>} />
          <Route path="/my-questionset-csv" element={<ProtectedRoutes><CreateFromCSV /></ProtectedRoutes>} />
          <Route path="/my-questionset/create/quiz" element={<ProtectedRoutes><Quiz /></ProtectedRoutes>} />
          <Route path="/my-questionset/create/flashcards" element={<ProtectedRoutes><Flashcards/></ProtectedRoutes>} />

          {/*DATASETS*/}
          <Route path="/my-datasets" element={<ProtectedRoutes><MyDatasets /></ProtectedRoutes>} />
          <Route path="/create-qa" element={<ProtectedRoutes><CreateQA /></ProtectedRoutes>} />
          <Route path="/create-qa-questionset" element={<ProtectedRoutes><CreateFromQuestionSet /></ProtectedRoutes>} />
         
          {/*FINETUNES*/}
          <Route path="/my-finetunes" element={<ProtectedRoutes><MyFinetunes /></ProtectedRoutes>} />
          
          

        </Routes>

      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
