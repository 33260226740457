import React from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import ProfileNavIcon from '../Components/ProfileNavIcon'

export default function Home() { 
    const navigator=useNavigate()
    return (
      <div className='w-screen h-screen flex flex-col items-center justify-center bg-emerald-50'>
          <ProfileNavIcon/>
          <div className='flex flex-col items-center text-center p-8'>
              {/* <p className='text-sm md:text-xl text-gray-500 -mb-2'>(Streamlined Automated LLM Conversational Agent Creator)</p> */}
              <h2 className='text-3xl md:text-7xl font-bold text-emerald-900'>StudyGazelle Generator</h2>
              <p className='text-md md:text-2xl'>A platform for experts to generate conversational agents for subject matter expertise</p>
              <motion.button onClick={()=>navigator("/projects")} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='text-md md:text-xl mt-4 mb-12 font-semibold text-emerald-900 px-4 py-2 border-4 border-emerald-900  rounded'>Get Started</motion.button>
              <img className='w-4/5 md:w-1/2 mt-12' src="https://i.postimg.cc/XJg3yvXS/1x1-Images-34.png"/>
          </div>
      </div>
    )
}

