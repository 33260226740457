import React, { useState } from 'react';
import { useAuth } from '../Hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUserWithEmail } from '../API/users';
import toast from 'react-hot-toast';

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signIn } = useAuth();
    const findUser=async(email)=>{
      const res = await getUserWithEmail(email)
      if(res==null){
        navigate("/expert-profiling")
      }
      console.log(res)
      localStorage.setItem("sal-generator-user",res.id)
    }
    const handleSignIn = async (e) => {
        e.preventDefault();
        const res = await signIn(email, password);
        console.log(res.data)
        if(res.data.user==null){
          toast.error("No accounts with this email. Try creating an account.")
        }
        if (res.data.user != null) {
            console.log(res.data.user.email)
            findUser(res.data.user.email)
            navigate("/projects")
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-cover bg-center">
            <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                <div>
                    <img
                        className="mx-auto h-12 w-auto"
                        src="/logo.png"
                        alt="SAL Dataset Generator"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Sign in to your account
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        >
                            Sign in
                        </button>
                    </div>
                </form>
                <div className="text-sm text-center">
                    <p onClick={()=>navigate("/create-account ")} className="font-medium cursor-pointer text-indigo-600 hover:text-indigo-500">
                        Don't have an account? Create account
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;