import {supabase} from '../supabaseClient'

export const getDatasetRowQA = async (row_id) => {
    const res = await supabase
        .from('generator_dataset_row')
        .select(`*, generator_dataset_row_chat(*)`)
        .eq('id', row_id)
        .order('created_at', { foreignTable: 'generator_dataset_row_chat' }); // Sort related data by created_at

    console.log(res.data[0]);
    return res.data[0];
};

export const createDatasetRow = async (conversation, dataset_id, ipTokens, opTokens) => {
    console.log("SAVING THIS: ",conversation)
    // First, create a dataset row
    console.log(conversation);
    const res = await supabase
        .from('generator_dataset_row')
        .insert([{
            dataset_id: dataset_id,
            first_question: conversation[0].content,
            total_input_tokens: ipTokens,
            total_output_tokens: opTokens,
        }])
        .select();

    const row_id = res.data[0].id;
    //console.log(row_id);

    let fullConversation = [];

    // Ensure inserts are performed sequentially to maintain order
    for (const conv of conversation) {
        const res = await supabase
            .from('generator_dataset_row_chat')
            .insert([{
                dataset_row_id: row_id,
                type: conv.type,
                content: conv.content,
                model: conv.model,
                input_tokens: conv.inputTokenCount,
                output_tokens: conv.outputTokenCount,
            }])
            .select();
        
        if (res.data) {
            console.log(res.data);
            fullConversation.push(res.data);
        }
    }
    console.log("FINAL FULL CONVERSATION ",fullConversation)
    return fullConversation;
};
