import React, { useState } from "react";
import {motion} from 'framer-motion'
import { MdClose } from "react-icons/md";
import {createDataset} from '../API/datasets'
import { createProjectQuestionSet } from "../API/questions";
import { createProjectFinetune } from "../API/finetune";

export default function CreateFinetuneModal({closeModal,project_id }) {

    const [formData, setFormData] = useState({});
    const [generating,setGenerating]=useState(false)
    const [selectedModel,setSelectedModel]=useState("")
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const questionsetCreate=async()=>{
      setGenerating(true)
      const data={
        "title":formData.title,
        "subtitle":formData.subtitle,
        "model":formData.model,
        "project_id": project_id  
      }
      console.log(data)
     const res=await createProjectFinetune(data)
      if(res){
        closeModal()
      }
    }
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="relative bg-white m-4 rounded-md shadow-lg w-full max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Create Finetune Model</h2>
                <h2 className="text-sm">Fill in the details to create a new finetune model</h2>
            </div>
            {<div className="space-y-4 p-12 ">
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Finetune Model Title
                  </label>
                  <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      placeholder="Finetune Model Title"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                  Finetune Model Subtitle
                  </label>
                  <input
                      type="text"
                      name="subtitle"
                      value={formData.subtitle}
                      onChange={handleChange}
                      placeholder="Finetune Model Subtitle"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                  Finetune Model 
                  </label>
                  <select
                    id="select"
                    name="model"
                    onChange={handleChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                        <option value="">-- Please choose an option --</option>
                        <option value="gpt-4o-mini-2024-07-18">gpt-4o-mini-2024-07-18</option>
                        <option value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</option>
                        <option value="gpt-3.5-turbo-0125">gpt-3.5-turbo-0125</option>
                    </select>
                </div>
            </div>}
            <div className="flex justify-end space-x-2 mt-6 rounded-b-md bg-emerald-700 py-4 px-4">
                {/* <button onClick={closeModal} className="text-sm font-semibold bg-red-500 text-white px-4 py-2 rounded">Cancel</button> */}
                <button onClick={questionsetCreate} className="text-sm font-semibold bg-white hover:bg-gray-200 text-emerald-700 px-4 py-2 rounded">{generating?"Creating...":"Create"}</button>
            </div>
        </motion.div>
      </div>
    );
  }