import {supabase} from '../supabaseClient'

export const getUserProjects=async(user_id)=>{
    console.log(user_id)
    const res=await supabase
    .from('generator_project') 
    .select()
    .eq('owner_id',user_id)
    console.log(res.data)
    return res.data
}
export const createProject=async(data)=>{
    const res=await supabase
    .from('generator_project') 
    .insert([{
        "title":data.title,
        "subtitle":data.subtitle,
        "topic":data.topic,
        "subtopic":data.subtopic,
        "description":data.description,
        "owner_id":data.owner_id    
    }]).select();
    if(res.data){
        console.log(res.data)
        return res.data
    }
    return null
    
}