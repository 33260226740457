import React,{useState} from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { MdAdd, MdDone,MdEdit } from 'react-icons/md'
import {motion} from 'framer-motion'
import { ThreeCircles } from 'react-loader-spinner'
import Papa from 'papaparse'
import toast from 'react-hot-toast'
import { createSetQuestion } from '../../API/questions'
import { useLocation } from 'react-router-dom'
import PaginatedQuestions from '../../Components/CreateFromQS/QuestionSetCreation/PaginatedQuestions'
const QuestionComponent =({ques,addQuestion})=>{
  const [added,setAdded]=useState(false)
  const clicked=()=>{
      setAdded(true)
      console.log("clik")
      addQuestion()
      
  }
  return(
  <button className='w-full cursor-pointer bg-white border my-1 max-w-7xl shadow-lg p-4 flex items-center justify-between'>
     
      <p className='text-sm :md:text-md mb-2'>{ques}</p>
      <p className='text-xl'>✅</p>
  </button>
  )
}
export default function CreateManual() {
  const location=useLocation()
  const questionset_id=location.state.questionset_id
  const [loading,setLoading]=useState(false)
  const  [uploading,setUploading] = useState(false)
  const [CSVData,setCSVData]=useState([])
  const [questionField,setQuestionField]=useState(null)
  const [questionDone,setQuestionDone]=useState(null)
  const [questions,setQuestions]=useState([])
  const [selectedQuestions,setSelectedQuestions]=useState([])
  const uploadQuestions = async () => {
    setUploading(true);
    //console.log(selectedQuestions)
    setQuestions([])
    if (!selectedQuestions || selectedQuestions.length === 0) {
      toast.error("You haven't selected any questions!");
      setUploading(false);
      return;
    }
  
    try {
      const uploadPromises = selectedQuestions.map(async (ques) => {
        const res = await createSetQuestion({
          "question": ques,
          "set_id": questionset_id
        });
        return res;
      });
  
      const results = await Promise.all(uploadPromises);
      toast.success("Successfully uploaded questions");
    } catch (error) {
      //console.error("Error uploading questions:", error);
      toast.error("Failed to upload questions. Please try again.");
    } finally {
        setQuestionField(null)
        setSelectedQuestions(false)
        setUploading(false);
    }
  };
  const addQuestion=()=>{
    setSelectedQuestions(ques=>[...ques,questionField])
    setQuestionField("")
  }
  
  
  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center '>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Dataset...</span></p>
    </div>)
  } 
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6 bg-emerald-50'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Dataset Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32 mb-4'>
          <h2 className='text-left text-xl md:text-3xl font-bold '>Create  <span className='text-emerald-700 '>Question</span></h2>
          <p className='text-sm font-gray-500'>Add questions to your question-set</p>
      </div>

      <div className='w-full max-w-7xl p-6 md:p-12 bg-white rounded border shadow'>
        
        <div className='w-full mt-4 md:mt-0 flex flex-col items-start space-y-3'>
            <div className='text-left mb-2 md:mb-0'>
              <h4 className=" w-full max-w-7xl text-2xl font-bold text-left text-emerald-700 ">Question</h4>
              <h4 className=" w-full max-w-7xl text-sm text-left text-gray-500 mb-4">Enter and add your question</h4>
            </div>
            <textarea placeholder='Enter the question' value={questionField} onChange={(e)=>setQuestionField(e.target.value)} className='w-full p-3 border border-gray-400' type="text" />
            <button onClick={addQuestion} className='px-3 py-2 bg-emerald-700 text-sm font-semibold text-white flex items-center justify-center hover:bg-emerald-800'><MdAdd/>Add Question</button>
          
        </div>
        {/* {questions.length==0 && <div className='w-full flex mt-8'>
            <button onClick={generateData} className='bg-emerald-700 text-white font-semibold px-4 py-2'>Get Questions</button>
        </div>} */}
      </div>

      {selectedQuestions.length>0 && <div className='w-full max-w-7xl p-6 border shadow rounded mt-4 bg-white'>
      
        <h4 className=" w-full max-w-7xl text-2xl font-bold text-left text-emerald-700 "> Added Questions </h4>
        <h4 className=" w-full max-w-7xl text-sm text-left text-gray-500 mb-4">Select and upload questions to add them to your questionset</h4>
        <PaginatedQuestions questions={selectedQuestions} selectedQuestions={selectedQuestions} setSelectedQuestions={setSelectedQuestions}/>
        <button onClick={uploadQuestions} className='w-full max-w-7xl mt-8 bg-emerald-700 text-center hover:bg-emerald-800 text-white font-semibold px-4 py-2'>Add to question set</button>

      </div>}
    </div>
  )
}
