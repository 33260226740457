import React, {  useState, useEffect} from "react";
import { MdClose } from "react-icons/md";
import {motion} from 'framer-motion'

export default function PromptCreatorModal({closeModal,previousPrompt,addPrompt }) {

    const [value, setValue] = useState(0);

    const [selectedQuestionType,setSelectedQuestionType]=useState(null)
    const [selectedQuestionFormat,setSelectedQuestionFormat]=useState(null)
    const [selectedDifficulty,setSelectedDifficulty]=useState(null)
    const [selectedGoal,setSelectedGoal]=useState(null)


    const questionTypes=["Conceptual","Procedural","Reflective","Behavourial","Assessment"]
    const difficulty=["Basic","Intermediate","Advanced","Expert"]
    const questionFormat=["Open Ended","Scenario Based","Case Study","Reflection Prompts","Problem Solving"]
    const pedagogicalGoal=["Knowledge Acquisition","Skill Development","Critical Thinking","Student Engagement"]
    const expectedResponseType=["Descriptive answers","Strategies","Action Plans","Reflective Answers","Justifications","Feedback Provision"]

    const handleChange = (event) => {
        setValue(Number(event.target.value));
    };

    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="relative bg-white m-4 rounded-md shadow-lg max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Question Generation Parameters</h2>
                <h2 className="text-sm">Changing these parameters would change the system prompt and the question/answer generation behaviour</h2>
            </div>
            
          
            <div className="space-y-4 px-12 py-8">
                {<div className='w-full p-4  space-y-4'>
                    
                    <div className="bg-gray-100 rounded border shadow-md p-4 my-2">
                        <label className="block text-lg text-gray-700 font-semibold mb-2">
                            Questioning Type
                        </label>
                        <div className='grid grid-cols-2 md:grid-cols-5 gap-4'>
                            {questionTypes.map(a=>(
                                <button onClick={()=>setSelectedQuestionType(a)} className={`p-4 border rounded-md shadow ${selectedQuestionType===a && "bg-emerald-700 text-white"}`}>
                                    <p className='text-sm text-center'>{a}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="bg-gray-100 rounded border shadow-md p-4 my-2">
                        <label className="block text-lg text-gray-700 font-semibold mb-2">
                            Difficulty Level
                        </label>
                        <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                            {difficulty.map(a=>(
                                <button onClick={()=>setSelectedDifficulty(a)} className={`p-4 border rounded-md shadow ${selectedDifficulty===a && "bg-emerald-700 text-white"}`}>
                                    <p className='text-sm text-center'>{a}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="bg-gray-100 rounded border shadow-md p-4 my-2">
                        <label className="block text-lg text-gray-700 font-semibold mb-2">
                            Question Format
                        </label>
                        <div className='grid grid-cols-2 md:grid-cols-5 gap-4'>
                            {questionFormat.map(a=>(
                                <button onClick={()=>setSelectedQuestionFormat(a)} className={`p-4 border rounded-md shadow ${selectedQuestionFormat===a && "bg-emerald-700 text-white"}`}>
                                    <p className='text-sm text-center'>{a}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="bg-gray-100 rounded border shadow-md p-4 my-2">
                        <label className="block text-lg text-gray-700 font-semibold mb-2">
                            Pedagogical Goal
                        </label>
                        <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                            {pedagogicalGoal.map(a=>(
                                <button onClick={()=>setSelectedGoal(a)} className={`p-4 border rounded-md shadow ${selectedGoal===a && "bg-emerald-700 text-white"}`}>
                                    <p className='text-sm text-center'>{a}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                    {/* <input
                        type="range"
                        min="0"
                        max="10"
                        value={value}
                        onChange={handleChange}
                        step="1"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-indigo-600"
    
                    /> */}
                    <div className='w-full flex justify-end'>
                        <button onClick={closeModal} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Save Prompt</button>
                    </div>
                </div>}  
                
            </div>
            
        </motion.div>
      </div>
    );
  }