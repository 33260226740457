import React,{useEffect, useState} from 'react'
import {motion} from 'framer-motion'
export default function Flashcard({item}) {
    const [flipped,setFlipped]=useState(false)
    return (
        <div>
            {!flipped && <motion.div onClick={()=>{setFlipped(true)}} initial={{scaleX:0}} animate={{scaleX:1}} transition={{duration:0.3}} className='h-full bg-white text-center font-semibold cursor-pointer p-4 py-12 rounded shadow border '>
                {item.question}
            </motion.div>}
            {flipped && <motion.div onClick={()=>{setFlipped(false)}} initial={{scaleX:0}} animate={{scaleX:1}} transition={{duration:0.3}} className='h-full bg-emerald-700 text-center font-semibold text-gray-200 cursor-pointer p-4 py-12 rounded shadow border '>
                {item.answer}
            </motion.div>}
        </div>
    )
}
