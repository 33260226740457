import React,{useEffect, useState} from 'react'
import {  FaAngleDown, FaAngleLeft, FaAngleUp } from 'react-icons/fa'
import {motion} from 'framer-motion'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PiSealQuestionFill } from "react-icons/pi";
import { MdAdd } from 'react-icons/md'
import { LuWrench } from "react-icons/lu";
import { FiDatabase } from "react-icons/fi";
import { RiTestTubeFill } from "react-icons/ri";
import CreateDatasetModal from '../Components/CreateDatasetModal'
import { getProjectDatasets } from '../API/datasets'
import { ThreeCircles } from 'react-loader-spinner'
import { getProjectQuestionSets } from '../API/questions'
import CreateQuestionsetModal from '../Components/CreateQuestionsetModal'
import { getProjectFinetunes } from '../API/finetune'
import CreateFinetuneModal from '../Components/CreateFinetuneModal'
const convertDate=(dateString)=>{
    // Create a new Date object
    const date = new Date(dateString);

    // Extract the day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = date.getFullYear();
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // 4th-20th always 'th'
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }
    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    return formattedDate; 
}
const DatasetCard=({data,project_id})=>{
    const navigate=useNavigate()
    console.log("PRJ",project_id)
    return (
        <motion.div whileHover={{translateY:"-5px"}} onClick={()=>navigate("/my-datasets",{state:{dataset_id:data.id,project_id:project_id}})} className='p-8 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700'>
            <p className='text-xl font-semibold text-left text-emerald-700'>{data.title}</p>
            <p className='text-sm text-left mb-4 text-gray-600'>{data.subtitle}</p>
            <div className='flex justify-start'>
                <h4 className='text-xs text-gray-600'><span className='px-2 py-1 rounded  bg-gray-200 text-gray-600'>created_at : {convertDate(data.created_at)}</span></h4>
            </div>
        </motion.div>
    )
}
const QuestionsetCard=({data})=>{
    const navigate=useNavigate()
    
    return (
        <motion.div whileHover={{translateY:"-5px"}} onClick={()=>navigate("/my-questionsets",{state:{question_set:data.id}})} className='p-8 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700'>
            <p className='text-xl font-semibold text-left text-emerald-700'>{data.title}</p>
            <p className='text-sm text-left mb-4 text-gray-600'>{data.subtitle}</p>
            <div className='flex justify-start'>
                <h4 className='text-xs text-gray-600'><span className='px-2 py-1 rounded  bg-gray-200 text-gray-600'>created_at : {convertDate(data.created_at)}</span></h4>
            </div>
        </motion.div>
    )
}
const FinetunedCard=({data})=>{
    const navigate=useNavigate()
    
    return (
        <motion.div whileHover={{translateY:"-5px"}} onClick={()=>navigate("/my-finetunes",{state:{finetune_id:data.id}})} className='p-8 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700'>
            <p className='text-xl font-semibold text-left text-emerald-700'>{data.title}</p>
            <p className='text-sm text-left mb-4 text-gray-600'>{data.subtitle}</p>
            <div className='flex justify-start'>
                <h4 className='text-xs text-gray-600'><span className='px-2 py-1 rounded  bg-gray-200 text-gray-600'>created_at : {convertDate(data.created_at)}</span></h4>
            </div>
        </motion.div>
    )
}

export default function ProjectDetails() {
    const navigate = useNavigate()
    const [loading,setLoading]=useState(false)
    const [activeTab,setActiveTab]=useState("question_set")
    const [openCreateDatasetModal,setOpenCreateDatasetModal]=useState(false)
    const [openCreateQuestionSetModal,setOpenCreateQuestionSetModal]=useState(false)
    const [openCreateFinetuneModal,setOpenCreateFinetuneModal]=useState(false)
    const [datasets,setDatasets]=useState([])
    const [finetunes,setFinetunes]=useState([])
    const [questionsets,setQuestionSets]=useState([])

    const params=useParams()
    const id= params.id
    console.log(id)
    const getDatasets=async()=>{
        setLoading(true)
        console.log(id)
        const res = await getProjectDatasets(id)
        if(res){
            setDatasets(res)
            setLoading(false)
        }
    }
    const getQuestionSets=async()=>{
        setLoading(true)
        console.log(id)
        const res = await getProjectQuestionSets(id)
        if(res){
            setQuestionSets(res)
            setLoading(false)
        }
    }
    const getFinetunes=async()=>{
        setLoading(true)
        console.log(id)
        const res = await getProjectFinetunes(id)
        if(res){
            setFinetunes(res)
            setLoading(false)
        }
    }
    const getAll = async()=>{
        await getDatasets()
        await getQuestionSets()
        await getFinetunes()
    }
    useEffect(()=>{
        getAll()
    },[])
    return (
        <div className='w-screen min-h-screen p-12 border rounded-md shadow my-2 flex flex-col items-center bg-emerald-50'>
             <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
                <motion.button onClick={()=>navigate("/projects")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                    <FaAngleLeft/>
                </motion.button>
                <h2 className='text-md md:text-xl font-semibold ml-4'>Manage Project </h2>

            </div>
            <div className='w-full max-w-7xl mt-12 flex items-center justify-center'>
                <motion.div onClick={()=>setActiveTab("question_set")}  className={`px-6 py-2 flex items-center border rounded-l-lg cursor-pointer ${activeTab==="question_set" ? "bg-emerald-700 text-white " :"bg-white hover:bg-emerald-100"} border-emerald-700 hover:shadow-md `}>
                    <PiSealQuestionFill className='w-6 h-6 mr-3'/>
                    <h2 className='text-sm font-bold'>Question Set</h2>
                </motion.div>
                <motion.div onClick={()=>setActiveTab("dataset")}  className={`px-6 py-2 flex items-center border cursor-pointer ${activeTab==="dataset" ? "bg-emerald-700 text-white " :"bg-white hover:bg-emerald-100"} border-emerald-700 hover:shadow-md `}>
                    <FiDatabase className='w-6 h-6 mr-3'/>
                    <h2 className='text-sm font-bold'>Dataset</h2>
                </motion.div>
                <motion.div onClick={()=>setActiveTab("finetune")}  className={`px-6 py-2 flex items-center border  rounded-r-lg cursor-pointer ${activeTab==="finetune" ? "bg-emerald-700 text-white " :"bg-white hover:bg-emerald-100"} border-emerald-700 hover:shadow-md `}>
                    <LuWrench className='w-6 h-6 mr-3'/>
                    <h2 className='text-sm font-bold'>Finetuning</h2>
                </motion.div>
                {/* <motion.div onClick={()=>setActiveTab("evaluation")}  className={`px-6 py-2 flex items-center border rounded-r-lg cursor-pointer ${activeTab==="evaluation" ? "bg-emerald-700 text-white " :"bg-white hover:bg-emerald-100"} border-emerald-700 hover:shadow-md `}>
                    <RiTestTubeFill className='w-6 h-6 mr-3'/>
                    <h2 className='text-sm font-bold'>Evaluation</h2>
                </motion.div> */}
            </div>
            {openCreateDatasetModal && <CreateDatasetModal closeModal={()=>{
                setOpenCreateDatasetModal(false)
                window.location.reload()
            }} project_id={id}/>}
            {openCreateQuestionSetModal && <CreateQuestionsetModal closeModal={()=>{
                setOpenCreateQuestionSetModal(false)
                window.location.reload()
            }} project_id={id}/>}
            {openCreateFinetuneModal && <CreateFinetuneModal 
                closeModal={()=>{
                    setOpenCreateFinetuneModal(false)
                    window.location.reload()}
                } 
                project_id={id}/>}
            <motion.div initial={{scaleY:0}} animate={{scaleY:1}} transition={{duration:0.3}} className='w-full max-w-7xl'>
                {/**QUESTION_SETS */}
                {activeTab==="question_set" &&  <div>
                    <div className='flex flex-col items-center justify-center my-4 md:my-8'>
                        <h5 className='font-bold text-lg md:text-3xl text-emerald-700'>Question Sets</h5>
                        <p className='text-md text-gray-500 '>Manage question sets</p>
                    </div>
                    
                    {loading && (<div className='w-full h-full flex items-center justify-center'>
                        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading datasets....</span></p>
                    </div>)}
                    {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        {questionsets.map(a=>(<QuestionsetCard data={a}/>))}
                        <motion.button onClick={()=>setOpenCreateQuestionSetModal(true)} whileHover={{translateY:"-5px"}} className='p-4 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700 text-gray-600  flex items-center flex-col justify-center'>
                            <MdAdd className='text-5xl p-2 rounded-full bg-gray-300'/>
                            <h2 className='font-semibold mt-2 '>Create New Question Set</h2>
                        </motion.button>
                    </div>}
                </div>}


                {/**DATASETS */}
                {activeTab==="dataset" && <div>
                    <div className='flex flex-col items-center justify-center my-4 md:my-8'>
                        <h5 className='font-bold text-lg md:text-3xl text-emerald-700'>Datasets</h5>
                        <p className='text-md text-gray-500 '>Manage datasets</p>
                    </div>
                    {loading && (<div className='w-full h-full flex items-center justify-center'>
                        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading datasets....</span></p>
                    </div>)}
                    {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        {datasets.map(a=>(<DatasetCard data={a} project_id={id}/>))}
                        <motion.button onClick={()=>setOpenCreateDatasetModal(true)} whileHover={{translateY:"-5px"}} className='p-4 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700 text-gray-600  flex items-center flex-col justify-center'>
                            <MdAdd className='text-5xl p-2 rounded-full bg-gray-300'/>
                            <h2 className='font-semibold mt-2 '>Create New Dataset</h2>
                        </motion.button>
                    </div>}
                </div>}


                {/**FINETUNES */}
                {activeTab==="finetune" && <div>
                    <div className='flex flex-col items-center justify-center my-4 md:my-8'>
                        <h5 className='font-bold text-lg md:text-3xl text-emerald-700'>Finetune</h5>
                        <p className='text-md text-gray-500 '>Manage finetunes</p>
                    </div>
                    {loading && (<div className='w-full h-full flex items-center justify-center'>
                        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>"Loading finetuned models....</span></p>
                    </div>)}
                    {!loading && <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        {finetunes.map(a=>(<FinetunedCard data={a} project_id={id}/>))}
                        <motion.button onClick={()=>setOpenCreateFinetuneModal(true)} whileHover={{translateY:"-5px"}} className='p-4 bg-white cursor-pointer rounded shadow hover:border hover:border-emerald-700 text-gray-600  flex items-center flex-col justify-center'>
                            <MdAdd className='text-5xl p-2 rounded-full bg-gray-300'/>
                            <h2 className='font-semibold mt-2 '>Finetune New Model</h2>
                        </motion.button>
                    </div>}
                </div>}

                {/* <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-emerald-700'>Collaborators</h5>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    
                </div> */}
            </motion.div>
        </div>
    )
}
