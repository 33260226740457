import React, { useRef, useState,useEffect } from 'react'
import { FiEdit2 } from "react-icons/fi";
export default function AnsweringPrompt({value,change}) {
    const [edit,setEdit]=useState(false);
    const [val,setVal]=useState(value)
    const textAreaRef=useRef(null);

    useEffect(() => {
        adjustTextareaHeight();
      }, [value]);
    
      const adjustTextareaHeight = () => {
        const textarea = textAreaRef.current;
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      };
  return (
    <div className='w-full p-6 bg-white'>
        <div className='w-full mb-2 flex justify-between'>
            <h4 className="mb-2  text-emerald-700 font-semibold text-lg">Answering Prompt</h4>
            {!edit && <button onClick={()=>setEdit(true)} className='p-2 border border-emerald-700 text-emerald-700 rounded shadow hover:text-white hover:bg-emerald-700'><FiEdit2/></button>}
        </div>
        {!edit && <p>{val}</p>}
        {edit && <textarea value={val} ref={textAreaRef} onChange={(e)=>setVal(e.target.value)} className='w-full p-3 border cols-4'/>}
         {edit && <div className='flex space-x-3'>
            <button onClick={()=>setEdit(false)} className='px-4 py-2 rounded shadow text-white bg-amber-500'>Cancel</button>    
            <button onClick={()=>{
                change(val)
                setEdit(false)
            }} className='px-4 py-2 rounded shadow text-white bg-emerald-700'>Save</button>    
        </div>}   
    </div>
  )
}
