import React, { useState } from 'react';

import { askGPT } from '../../Models/GPTCall';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { createSetQuestion } from '../../API/questions';
import { motion } from 'framer-motion';
import { FaAngleDown, FaAngleLeft, FaAngleUp } from 'react-icons/fa';
import LoadingIndicator from '../../Components/LoadingIndicator';
import { pdfjs } from 'react-pdf';
import PaginatedQuestions from '../../Components/CreateFromQS/QuestionSetCreation/PaginatedQuestions';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFReader = () => {
    const location=useLocation()
    const navigate=useNavigate()
    const questionset_id=location.state.questionset_id
    const [pdfText, setPdfText] = useState([]);
    const [reading,setReading]=useState(false)
    const [questions,setQuestions]=useState(null)
    const [generating,setGenerating]=useState(false)
    const [generated,setGenerated]=useState(false)
    const [uploading,setUploading]=useState(false)

    const [selectedQuestions,setSelectedQuestions]=useState([])



    const uploadQuestions = async () => {
        setUploading(true);
        
        if (!selectedQuestions || selectedQuestions.length === 0) {
          toast.error("You haven't selected any questions!");
          setUploading(false);
          return;
        }
      
        try {
          const uploadPromises = selectedQuestions.map(async (ques) => {
            const res = await createSetQuestion({
              "question": ques,
              "set_id": questionset_id
            });
            return res;
          });
      
          const results = await Promise.all(uploadPromises);
          
          //console.log("Upload results:", results);
          
          toast.success("Successfully uploaded questions");
        } catch (error) {
          //console.error("Error uploading questions:", error);
          toast.error("Failed to upload questions. Please try again.");
        } finally {
            setQuestions([])
            setPdfText([])
            window.history.back()
            setQuestions(false)
            setGenerated(false)
            setUploading(false);
        }
      };



  const handleFileChange = async (event) => {
    setReading(true)
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
        //console.log("File uploaded")
      const reader = new FileReader();
      reader.onload = async (e) => {
        const typedarray = new Uint8Array(e.target.result);
        const pdf = await pdfjs.getDocument(typedarray).promise;
        let fullText = [];
        
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          //console.log(textContent)
          const pageText = textContent.items.map(item => item.str).join(' ');
          fullText.push(pageText)
        }
        //console.log(fullText)
        setPdfText(fullText);
        setReading(false)
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Please select a valid PDF file.');
    }
  };
  const generateQuestions = async () => {
    setGenerating(true);
    let allQuestions = [];
  
    try {
      const questionPromises = pdfText.map(async (a, idx) => {
        try {
          const res = await askGPT("gpt-4o-mini", [
            {
              role: "system",
              content: "You are an intelligent agent that creates questions from the text that is provided. You will create questions and put them in an array. If you don't put them in an array, you will be fined 500 dollars. Just give me the array and nothing else. Don't do any formatting. Just give me the array. Now make questions from the following page:\n\n"
            },
            {
              role: "user",
              content: a
            },
          ]);
  
          const resArray = res.choices[0].message.content;
          const arr = JSON.parse(resArray);
          return arr;
        } catch (e) {
          //console.log("ERROR in page : ", idx + 1);
          return [];
        }
      });
  
      const results = await Promise.all(questionPromises);
      allQuestions = results.flat();
  
      //console.log(allQuestions);
      setQuestions(allQuestions);
      setGenerated(true);
    } catch (error) {
      //console.error("Error generating questions:", error);
    } finally {
      setGenerating(false);
    }
  };
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Generate Questions</h2>

      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
          <h2 className='text-left text-3xl font-bold '>Create <span className='text-emerald-700 '>Questions</span></h2>
          <p className='text-sm font-gray-600'>Upload any file and generate questions from it</p>
      </div>
      {pdfText.length===0 && <div>
        <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="my-4 p-2 border border-gray-300 rounded"
        />
      </div>}
      {reading && <LoadingIndicator message='Reading PDF File....'/>}
      {generating && <LoadingIndicator message='Generating Questions....'/>}
      {uploading && <LoadingIndicator message='Uploading Selected Questions....'/>}


      {/** PDF HAS BEEN PARSED*/}
      {pdfText.length!==0 && !generated && !generating && <div className='w-full max-w-7xl p-6 my-4 border-2 border-dashed border-emerald-700 bg-emerald-50 flex flex-col items-center justify-center'>
        {<h4 className=" w-full max-w-7xl text-xl font-bold text-center text-emerald-700">Chunks have been generated</h4>}
        {<h4 className=" w-full max-w-7xl text-sm text-center text-gray-500 mb-4">Generate questions to continue</h4>}
        {<motion.button onClick={generateQuestions} whileHover={{scale:1.1}} className='p-4 cursor-pointer rounded border hover:border-2  bg-emerald-700 font-semibold text-white hover:bg-emerald-800   flex items-center flex-col justify-center'>{generating ? "Generating Questions...." : "Generate Questions"}</motion.button>}
      </div>}
      {/* <div className='w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-4'>
        {!generated && !generating && pdfText.map((a,idx)=>(
            <PageContent data={a} page={idx+1}/>
        ))}
      </div> */}
      
      {/**QUESTIONS HAVE BEEN GENERATED */}
     {generated && questions.length!==0 &&  <div className='w-full max-w-7xl p-6 border shadow rounded mt-4'>
        {/* {generated && questions.map((a,idx)=>(
            <QuestionComponent ques={a} addQuestion={()=>setSelectedQuestions(questions=>[...questions,a])}/>
        ))} */}
        <h4 className=" w-full max-w-7xl text-2xl font-bold text-left text-emerald-700 "> Generated Questions </h4>
        <h4 className=" w-full max-w-7xl text-sm text-left text-gray-500 mb-4">Select and upload questions to add them to your questionset</h4>
        {generated && <PaginatedQuestions
        questions={questions}
        selectedQuestions={selectedQuestions}
        setSelectedQuestions={setSelectedQuestions}
      />}
        {generated && <motion.button onClick={uploadQuestions} className='w-full py-2 cursor-pointer rounded border  bg-emerald-700 font-semibold text-white hover:bg-emerald-800  flex items-center flex-col justify-center'>{uploading ?"Uploading Questions...." : "Upload Questions"}</motion.button>}
      </div>}
      
      
    </div>
  );
};

export default PDFReader;