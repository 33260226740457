import {supabase} from '../supabaseClient'

export const createUser=async(usr)=>{
    const res=await supabase
    .from('generator_user') 
    .insert([{
        "email":usr.email,
        "name":usr.name,
        "gender":usr.gender,
        "occupation":usr.occupation,
        "degree":usr.degree,
        "llm_usage":usr.llm_usage,
        "topic":usr.topic,
        "learning_period":usr.learning_period,
       
    }]).select();
    if(res.data[0]!=null){
        localStorage.setItem("sal-generator-user",res.data[0].id)
        console.log(res.data[0])
        return res.data[0]
    }
     return null
    
}
export const updateUser=async(usr)=>{
    const res=await supabase
    .from('evaluator_user') 
    .update({
        "email":usr.email,
        "name":usr.name,
        "gender":usr.gender,
        "occupation":usr.occupation,
        "degree":usr.degree,
        "llm_usage":usr.llm_usage,
        "topic":usr.topic,
        "learning_period":usr.learning_period,
     }).eq('email',usr.email)
     .select();
    console.log(res.data)
    return res.data
}
export const getUserWithEmail=async(email)=>{
    const res=await supabase
    .from('generator_user') 
    .select()
    .eq('email',email)
    console.log("user",res.data)
    if(res.data[0]){
        localStorage.setItem("sal-generator-user",res.data[0].id)
        return res.data[0]
    }
    //console.log(res.data[0])
    return null
}