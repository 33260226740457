import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import Flashcard from '../../../Components/CreateFromQS/Flashcards/Flashcard'
import { MdAdd } from 'react-icons/md'
import CreateFlashcardModal from '../../../Components/CreateFromQS/Flashcards/CreateFlashcardModal'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { askGPT } from '../../../Models/GPTCall'
export default function FlashCards() {
    const navigate=useNavigate()
    const [uploadDocumentModal,setUploadDocumentModal]=useState(false)
    const [topicName,setTopicName]=useState()
    const [flashCards,setFlashCards]=useState([])
    const [generating,setGenerating]=useState(true)
    const [doneGeneration,setDoneGeneration]=useState(false)
    const messages = [
        {
            "role": "system",
            "content":`You want to teach a student the ${topicName}. You basically create flashcards for them to memorize things faster. The flashcards that you have are generated in the following JSON format :\n
                {
                "question":"The word that you want to teach",
                "answer":"The meaning of that word"
                }\n
            Don't write anything else. Just the JSON data. Return only the JSON data in an ARRAY. Nothing else. If you return anything else you will be penalized 500 dollars.
            `
        },
        {
            "role": "user",
            "content":"Now generate 10 such flashcards"
        }
    ]
    const getFlashcards=async()=>{
        const res=await askGPT("gpt-4o-mini",messages)
        console.log(res.choices[0].message.content)
        setFlashCards(JSON.parse(res.choices[0].message.content))
        setGenerating(false)
        setDoneGeneration(true)
    }
    const closeModal=()=>{
        setUploadDocumentModal(false)
    }
    // useEffect(()=>{
    //     getFlashcards()
    // },[])
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
        <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:justify-between md:p-6 p-4 bg-white'>
            <div className='flex items-center'>
                <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                    <FaAngleLeft/>
                </motion.button>
                <h2 className='text-md md:text-xl font-semibold ml-4 '>Create FlashCards </h2>
            </div>
            <p className='hidden md:block text-sm font-semibold text-gray-500'>Flashcards : <span className='text-lg font-semibold text-gray-200 rounded-lg bg-gray-700 p-2'>{flashCards.length}</span></p>
        </div>
        <button onClick={()=>setUploadDocumentModal(true)} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><MdAdd className='text-xl'/>Create New</button>
        {doneGeneration && <div className='w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-4 mt-16 md:mt-32'>
               {!generating && flashCards.map(a=>(<Flashcard key={a.question} item={a}/>))}
               {generating && <p>Generating Flashcards...</p>}
        </div>}
        {uploadDocumentModal && <CreateFlashcardModal setTopic={(e)=>setTopicName(e)} generateFlashcards={getFlashcards} closeModal={closeModal}/>}
    </div>
  )
}
