import React,{useState,useEffect} from 'react'
import { MdDone, MdEdit } from 'react-icons/md'
import PromptCreatorModal from './PromptCreatorModal'
import { updateQuestionSetSubtitle, updateQuestionSetTitle } from '../API/questions'

export default function QuestionsetDetailsForm({data}) {
    const [title,setTitle]=useState(data.title)
    const [subtitle,setSubtitle]=useState(data.subtitle)
    const [editingTitle,setEditingTitle]=useState(false)
    const [editingSubtitle,setEditingSubtitle]=useState(false)


    const [openPromptCreatorModal,setOpenPromptCreatorModal]=useState(false)
  
    const changeTitle=async()=>{
      const res = await updateQuestionSetTitle(title,data.id)
      window.location.reload()
    }
    const changeSubtitle=async()=>{
      const res = await updateQuestionSetSubtitle(subtitle,data.id)
      window.location.reload()
    }
  
    return(<div className='w-full space-y-8'>
      {openPromptCreatorModal && <PromptCreatorModal closeModal={()=>setOpenPromptCreatorModal(false)}/>}
      <div className='w-full flex items-end'>
        <div className='w-full '>
          <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
          Question Set Title
          <button onClick={()=>{setEditingTitle(!editingTitle)}} className='p-3 rounded font-semibold text-xl border  text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>{editingTitle ? <MdDone/>:<MdEdit/>}</button>
          </label>
          {editingTitle && <input
              type="text"
              name="name"
              value={title}
              onChange={(e)=>{setTitle(e.target.value)}}
              placeholder="Enter Your Prompt"
              className="w-full text-sm p-3 border border-gray-300 rounded mb-2"
          />}
          {editingTitle && <button onClick={()=>setEditingTitle(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
          {editingTitle && <button onClick={changeTitle} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Title</button>}
          {!editingTitle && <p className='font-semibold rounded p-3 rounded-b shadow bg-white text-justify'>{title}</p>}
        </div>
      </div>

      <div className='w-full flex items-end'>
        <div className='w-full '>
          <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
          Question Set Subtitle
          <button onClick={()=>{setEditingSubtitle(!editingSubtitle)}} className='p-3 rounded font-semibold text-xl border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>{editingSubtitle ? <MdDone/>:<MdEdit/>}</button>
          </label>
          {editingSubtitle && <input
              type="text"
              name="name"
              value={subtitle}
              onChange={(e)=>{setSubtitle(e.target.value)}}
              placeholder="Enter Your Prompt"
              className="w-full text-sm p-3 border border-gray-300 rounded mb-2"
          />}
          {editingSubtitle && <button onClick={()=>setEditingSubtitle(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
          {editingSubtitle && <button onClick={changeSubtitle} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Subtitle</button>}
          {!editingSubtitle && <p className='font-semibold rounded p-3 rounded-b shadow bg-white text-justify'>{subtitle}</p>}
        </div>
      </div>
      
    </div>)
}
