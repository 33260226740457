import React, { useState } from "react";
import {motion} from 'framer-motion'
import { MdClose } from "react-icons/md";
import {createProject} from '../API/projects'

export default function CreateProjectModal({closeModal,user_id }) {

    const [formData, setFormData] = useState({});
    const [generating,setGenerating]=useState(false)
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const projectCreate=async()=>{
      setGenerating(true)
      const data={
        "title":formData.title,
        "subtitle":formData.subtitle,
        "topic":formData.topic,
        "subtopic":formData.subtopic,
        "description":formData.description,
        "owner_id": user_id  
      }
      const res=await createProject(data)
      if(res){
        closeModal()
      }
    }
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="relative bg-white w-full m-4 rounded-md shadow-lg max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Create Projects</h2>
                <h2 className="text-sm">Fill in the details to create a new project</h2>
            </div>
            {<div className="space-y-4 p-12 ">
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Project Title
                  </label>
                  <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      placeholder="Project Title"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Project Subtitle
                  </label>
                  <input
                      type="text"
                      name="subtitle"
                      value={formData.subtitle}
                      onChange={handleChange}
                      placeholder="Project Subtitle"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Project Topic
                  </label>
                  <input
                      type="text"
                      name="topic"
                      value={formData.topic}
                      onChange={handleChange}
                      placeholder="Project Topic"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Project Subtopic (Optional)
                  </label>
                  <input
                      type="text"
                      name="subtopic"
                      value={formData.subtopic}
                      onChange={handleChange}
                      placeholder="Project Subtopic"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm text-gray-700 font-semibold">
                      Project Description
                  </label>
                  <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Project Description"
                      className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                  />
                </div>
                
            </div>}
            <div className="flex justify-end space-x-2 mt-6 rounded-b-md bg-emerald-700 py-4 px-4">
                {/* <button onClick={closeModal} className="text-sm font-semibold bg-red-500 text-white px-4 py-2 rounded">Cancel</button> */}
                <button onClick={projectCreate} className="text-sm font-semibold bg-white hover:bg-gray-200 text-emerald-700 px-4 py-2 rounded">{generating?"Creating...":"Create"}</button>
            </div>
        </motion.div>
      </div>
    );
  }