import React from 'react'
import { useAuth } from './Hooks/AuthContext';
import Login from './Pages/Login';

export default function ProtectedRoutes({children}) {
    const { user, signOut } = useAuth();
    if (!user) {
        return <Login/>
      }
    return (
        <div>{children}</div>
    )
}
