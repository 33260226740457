import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { BiUndo } from 'react-icons/bi'
import { MdAbc, MdAdd, MdDone } from 'react-icons/md'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CgClose } from 'react-icons/cg'
import { askGPT } from '../../../Models/GPTCall'
import CreateQuizModal from '../../../Components/CreateFromQS/Quiz/CreateQuizModal'
export default function Quiz() {
    const navigate=useNavigate()
  
    const [correctAnswers,setCorrectAnswers]=useState([])
    const [selectedAnswers,setSelectedAnswers]=useState([])
    const [change,setChange]=useState(false) //for reloading the selected options
    const [compare,setCompare]=useState(false) 
    const [correctCount,setCorrectCount]=useState(-1)

    const [createQuizModal,setCreateQuizModal]=useState(false)
    const [topicName,setTopicName]=useState()


    const [generationComplete,setGenerationComplete]=useState(false)
    const [quizContent,setQuizContent]=useState([])
    const [questionCount,setQuestionCount]=useState(5)
    // const quizContent=[
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:true},
    //             {value:"Mango",correct:false},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:false},
    //         ]
    //     },
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:false},
    //             {value:"Mango",correct:true},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:false},
    //         ]
    //     },
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:false},
    //             {value:"Mango",correct:false},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:true},
    //         ]
    //     },
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:false},
    //             {value:"Mango",correct:false},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:true},
    //         ]
    //     },
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:false},
    //             {value:"Mango",correct:false},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:true},
    //         ]
    //     },
    //     {
    //         question:"What is the national fruit of Bangladesh?",
    //         answers:[
    //             {value:"Jackfruit",correct:false},
    //             {value:"Mango",correct:false},
    //             {value:"Banana",correct:false},
    //             {value:"Kiwi",correct:true},
    //         ]
    //     },
        // {
        //     question:"What is the national fruit of Bangladesh?",
        //     answers:[
        //         {value:"Jackfruit",correct:false},
        //         {value:"Mango",correct:false},
        //         {value:"Banana",correct:false},
        //         {value:"Kiwi",correct:true},
        //     ]
        // },
    // ]
    const [generating,setGenerating]=useState(false)
    const [doneGeneration,setDoneGeneration]=useState(false)
    const messages = [
        {
            "role": "system",
            "content":`You want to evaluate a student on ${topicName}. You basically create quized to evaluate their learning on a particular topic. The quiz questions that you have are generated in the following JSON format :\n
                {
                    question:The question that you want to ask,
                    answers:[
                        {value:"Value1",correct:"A boolen value whether the answer is correct or not"},
                        {value:"Value2",correct:"A boolen value whether the answer is correct or not"},
                        {value:"Value3",correct:"A boolen value whether the answer is correct or not"},
                        {value:"Value4",correct:"A boolen value whether the answer is correct or not"},
                    ]
                },
            Don't write anything else. Just the JSON data. Return only the JSON data in an ARRAY. Nothing else. If you return anything else you will be penalized 500 dollars.
            `
        },
        {
            "role": "user",
            "content":`Now generate ${questionCount} such quiz questions`
        }
    ]
    const getQuestions=async()=>{
        setGenerating(true)
        const res=await askGPT("gpt-4o-mini",messages)
       // console.log(res.choices[0].message.content)
        setQuizContent(JSON.parse(res.choices[0].message.content))
        generateAnswerSheet(JSON.parse(res.choices[0].message.content))
        setGenerating(false)
        setDoneGeneration(true)
    }
    const closeModal=()=>{
        setCreateQuizModal(false)
    }
    const generateAnswerSheet=(quizContent)=>{
        let answersSheet=[]
        let selectedSheet=[]
        console.log(quizContent)
        quizContent.map((a)=>{
            a["answers"].map((b)=>{
                if(b["correct"]){
                    console.log("OKEDOK ",b["value"])
                    answersSheet.push(b["value"])
                    selectedSheet.push(null) // Putting empty in the selected sheet to maintain the length
                }
            })
        })
        console.log("INITIAL ",answersSheet)
        console.log("INITIAL ",selectedAnswers)
        setCorrectAnswers(answersSheet)
        setSelectedAnswers(selectedSheet)
    }
    const changeSelectedOption=(idx,value)=>{
        if(compare){
            return
        }
        selectedAnswers[idx]=value
        //setSelectedAnswers()
        setChange(!change)
        console.log(selectedAnswers)
        //setSelectedAnswers([selectedArr])
        //alert([selectedArr])
    }
    const compareAnswers=()=>{
        setCompare(true)
        let score=0
        for(let i=0;i<correctAnswers.length;i=i+1){
            if(correctAnswers[i]===selectedAnswers[i]){
                score=score+1
            }
        }
        setCorrectCount(score)
        //alert(score)
    }
    // useEffect(()=>{
    //     generateAnswerSheet(quizContent)
    // },[])
  return (
    <div className='w-screen h-screen p-8 pt-20'>
            {createQuizModal && <CreateQuizModal setCount={(e)=>setQuestionCount(e)} setTopic={(e)=>setTopicName(e)} generateQuiz={getQuestions} closeModal={closeModal}/>}
            {!doneGeneration && <button onClick={()=>setCreateQuizModal(true)} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><MdAdd className='text-xl'/>Create New</button>}
            <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex items-center justify-start md:justify-between p-4 md:p-6 bg-white'>
                <div className='flex items-center'>
                    <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                        <FaAngleLeft/>
                    </motion.button>
                    <h2 className='text-md md:text-xl font-semibold ml-4'>Quiz<span className='hidden md:block text-emerald-700'></span></h2>
                </div>
                <p className='hidden md:block text-sm font-semibold text-gray-500'>Questions : <span className='text-lg font-semibold text-gray-200 rounded-lg bg-gray-700 p-2'>{quizContent.length}</span></p>
            </div>
            {generating && <div>Generating questions...</div>}
            <div className='w-full flex flex-col items-center justify-center'>
                {compare && <div className='w-full max-w-7xl p-6 my-6 rounded shadow bg-gray-100'>
                    <h2 className='text-sm font-semibold text-gray-500 mb-2'>Final Score</h2>
                    <h2 className='text-md font-semibold'> <span className='text-2xl p-2 bg-emerald-700 rounded text-gray-200'>{correctCount}</span> out of <span className='text-2xl p-2 bg-gray-700 rounded text-gray-200'>{correctAnswers.length}</span></h2>
                </div>}
                {doneGeneration && !compare && <div className='w-full max-w-7xl mb-4 flex justify-end gap-x-3'>
                    <motion.button onClick={compareAnswers} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='px-4 flex items-center space-x-2 py-2 rounded shadow bg-gray-700 hover:bg-gray-800 text-sm font-semibold text-gray-200'>
                        <BiUndo className='text-xl'/>
                        <p>Regenerate</p>
                    </motion.button>
                    <motion.button onClick={compareAnswers} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='px-4 flex items-center space-x-2 py-2 rounded shadow bg-emerald-700 hover:bg-emerald-800 text-sm font-semibold text-gray-200'>
                        <MdDone className='text-xl'/>
                        <p>Submit</p>   
                    </motion.button>
                </div>}
                <div className='w-full max-w-7xl overflow-y-auto'>
                    {quizContent.map((a,idx)=>(
                        <div className='border border-gray-200 rounded-lg shadow bg-white  my-2'>
                            <div className='flex items-center bg-gray-100 p-4 rounded font-semibold'>
                                {compare && (correctAnswers[idx]===selectedAnswers[idx]?<MdDone className='text-emerald-700 mr-2'/>:<CgClose className='text-red-500 mr-2'/>)}
                                <h5><span className={!compare && 'text-emerald-700'}> Question {idx+1} : </span>{a.question}</h5>
                            </div>
                            <div className=' p-4 grid grid-cols-1 md:grid-cols-2 gap-2 mt-2'>
                                {a.answers.map((b)=>(
                                <div className='w-full'>
                                        {!compare && <motion.button onClick={()=>changeSelectedOption(idx,b.value)} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className={`w-full p-4  ${selectedAnswers[idx]===b.value?"bg-gray-700 text-white":"bg-white text-gray-800"} rounded border shadow hover:border-emerald-700`}>
                                            <h6>{b.value}</h6>
                                        </motion.button>}
                                        {compare && selectedAnswers[idx]!==b.value && correctAnswers[idx]!==b.value &&  <motion.button onClick={()=>changeSelectedOption(idx,b.value)} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className={`w-full p-4 bg-white rounded border shadow hover:border-emerald-700`}>
                                            <h6>{b.value}</h6>
                                        </motion.button>}
                                        {compare && selectedAnswers[idx]!==b.value && correctAnswers[idx]===b.value &&  <motion.button onClick={()=>changeSelectedOption(idx,b.value)} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className={`w-full p-4 bg-emerald-700 text-white rounded border shadow hover:border-emerald-700`}>
                                            <h6>{b.value}</h6>
                                        </motion.button>}
                                        {compare && selectedAnswers[idx]===b.value && selectedAnswers[idx]===correctAnswers[idx] &&  <motion.button onClick={()=>changeSelectedOption(idx,b.value)} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className={`w-full p-4 bg-emerald-700 text-white rounded border shadow hover:border-emerald-700`}>
                                            <h6>{b.value}</h6>
                                        </motion.button>}
                                        {compare && selectedAnswers[idx]===b.value && selectedAnswers[idx]!==correctAnswers[idx] &&  <motion.button onClick={()=>changeSelectedOption(idx,b.value)} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className={`w-full p-4 bg-red-500 text-white rounded border shadow hover:border-emerald-700`}>
                                            <h6>{b.value}</h6>
                                        </motion.button>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

       
        
    </div>
  )
}
