import {supabase} from '../supabaseClient'

export const getProjectQuestionSets=async(project_id)=>{
    const res=await supabase
    .from('generator_question_set') 
    .select()
    .eq('project_id',project_id)
    //console.log(res.data)
    return res.data
}
export const getQuestionsetByID=async(q_id)=>{
    //console.log(q_id)
    const res=await supabase
    .from('generator_question_set') 
    .select()
    .eq('id',q_id)
    //console.log(res.data)
    return res.data[0]
}
export const createProjectQuestionSet=async(data)=>{
    const res=await supabase
    .from('generator_question_set') 
    .insert([{
        "title":data.title,
        "subtitle":data.subtitle,
        "project_id":data.project_id    
    }]).select();
    if(res.data){
        //console.log(res.data)
        return res.data
    }
    return null
    
}

export const getQuestionsBySet=async(set_id)=>{
    const res=await supabase
    .from('generator_question') 
    .select()
    .eq('set_id',set_id)
    //console.log(res.data)
    return res.data
}
export const createSetQuestion=async(data)=>{
    const res=await supabase
    .from('generator_question') 
    .insert([{
        "question":data.question,
        "set_id":data.set_id,
        "used":false    
    }]).select();
    if(res.data){
        //console.log(res.data)
        return res.data
    }
    return null
    
}
export const updateQuestionSetTitle=async(new_title,qs_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_question_set')
            .update({ title: new_title })
            .eq('id', qs_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        //console.error('Error updating message:', error);
        throw error;
    }
}
export const updateQuestionSetSubtitle=async(new_subtitle,qs_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_question_set')
            .update({ subtitle: new_subtitle })
            .eq('id', qs_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
}