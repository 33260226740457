import React,{useState,useEffect} from 'react'
import { updateAnsweringSystemPrompt, updateQuestioningSystemPrompt,updateTitle ,updateSubtitle} from '../API/datasets'
import { MdDone, MdEdit } from 'react-icons/md'
import PromptCreatorModal from './PromptCreatorModal'

export default function DatasetDetailsForm({data}) {
    const [title,setTitle]=useState(data.title)
    const [subtitle,setSubtitle]=useState(data.subtitle)
    const [editingTitle,setEditingTitle]=useState(false)
    const [editingSubtitle,setEditingSubtitle]=useState(false)
    const [editingQuesPrompt,setEditingQuesPrompt]=useState(false)
    const [quesPrompt,setQuesPrompt]=useState(data.questioning_system_prompt)
    const [editingAnsPrompt,setEditingAnsPrompt]=useState(false)
    const [ansPrompt,setAnsPrompt]=useState(data.answering_system_prompt)

    const [openPromptCreatorModal,setOpenPromptCreatorModal]=useState(false)
  
    const changeTitle=async()=>{
      const res = await updateTitle(title,data.id)
      window.location.reload()
    }
    const changeSubtitle=async()=>{
      const res = await updateSubtitle(subtitle,data.id)
      window.location.reload()
    }
    const changeQuesPrompt=async()=>{
      const res = await updateQuestioningSystemPrompt(quesPrompt,data.id)
      window.location.reload()
    }
    const changeAnsPrompt=async()=>{
      const res = await updateAnsweringSystemPrompt(ansPrompt,data.id)
      window.location.reload()
    }
    return(<div className='w-full space-y-8'>
      {openPromptCreatorModal && <PromptCreatorModal closeModal={()=>setOpenPromptCreatorModal(false)}/>}
      <div className='w-full flex items-end'>
        <div className='w-full '>
          <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
          Dataset Title
          {!editingTitle && <button onClick={()=>{setEditingTitle(!editingTitle)}} className='p-3 rounded font-semibold text-xl border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>{editingTitle ? <MdDone/>:<MdEdit/>}</button>}
          </label>
          {editingTitle && <input
              type="text"
              name="name"
              value={title}
              onChange={(e)=>{setTitle(e.target.value)}}
              placeholder="Enter Your Prompt"
              className="w-full text-sm p-3 border border-gray-300 rounded mb-2"
          />}
          {editingTitle && <button onClick={()=>setEditingTitle(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
          {editingTitle && <button onClick={changeTitle} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Title</button>}
          {!editingTitle && <p className='font-semibold rounded p-3 rounded-b shadow bg-white text-justify'>{title}</p>}
        </div>
      </div>

      <div className='w-full flex items-end'>
        <div className='w-full '>
          <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
          Dataset Subtitle
          <button onClick={()=>{setEditingSubtitle(!editingSubtitle)}} className='p-3 rounded font-semibold text-xl border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>{editingSubtitle ? <MdDone/>:<MdEdit/>}</button>
          </label>
          {editingSubtitle && <input
              type="text"
              name="name"
              value={subtitle}
              onChange={(e)=>{setSubtitle(e.target.value)}}
              placeholder="Enter Your Prompt"
              className="w-full text-sm p-3 border border-gray-300 rounded mb-2"
          />}
          {editingSubtitle && <button onClick={()=>setEditingSubtitle(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
          {editingSubtitle && <button onClick={changeSubtitle} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Subtitle</button>}
          {!editingSubtitle && <p className='font-semibold rounded p-3 rounded-b shadow bg-white text-justify'>{subtitle}</p>}
        </div>
      </div>

      {/* <div className='w-full '>
        <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
            Questioning System Prompt <button className='p-3 text-xl bg-white text-emerald-700 border rounded border-emerald-700 hover:text-white hover:bg-emerald-700' onClick={()=>setOpenPromptCreatorModal(true)}>{editingQuesPrompt?<MdDone className='cursor-pointer'/>:<MdEdit className='cursor-pointer '/>}</button>
        </label>
        {!editingQuesPrompt && <p className='text-sm p-3 md:p-4 rounded-b shadow bg-white text-justify border'>{data.questioning_system_prompt}</p>}
        {editingQuesPrompt && <textarea
            type="text"
            name="name"
            value={quesPrompt}
            onChange={(e)=>{setQuesPrompt(e.target.value)}}
            placeholder="Enter Your Prompt"
            className="w-full text-sm p-3 border border-gray-300 rounded"
        />}
        {editingQuesPrompt && <button onClick={()=>setEditingQuesPrompt(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
        {editingQuesPrompt && <button onClick={changeQuesPrompt} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Prompt</button>}
      </div>
      <div className='w-full '>
        <label className="text-md text-emerald-700 font-semibold flex items-center justify-between border bg-white p-2 px-4 rounded-t">
            Answering System Prompt <button className='p-3 text-xl bg-white text-emerald-700 border rounded border-emerald-700 hover:text-white hover:bg-emerald-700' onClick={()=>setEditingAnsPrompt(!editingAnsPrompt)}>{editingAnsPrompt?<MdDone className='cursor-pointer'/>:<MdEdit className='cursor-pointer '/>}</button>
        </label>
        {!editingAnsPrompt && <p className='text-sm p-2 rounded-b shadow bg-white text-justify'>{data.answering_system_prompt}</p>}
        {editingAnsPrompt && <textarea
            type="text"
            name="name"
            value={ansPrompt}
            onChange={(e)=>{setAnsPrompt(e.target.value)}}
            placeholder="Enter Your Prompt"
            className="w-full text-sm p-3 border border-gray-300 rounded"
        />}
        {editingAnsPrompt && <button onClick={()=>setEditingAnsPrompt(false)} className='text-sm font-semibold px-4 py-2 bg-yellow-300 text-gray-600 rounded hover:bg-yellow-400 mr-2'>Cancel</button>}
        {editingAnsPrompt && <button onClick={changeAnsPrompt} className='text-sm font-semibold px-4 py-2 bg-emerald-700 text-white rounded hover:bg-emerald-800'>Change Prompt</button>}
      </div> */}
    </div>)
}
