import React,{useEffect, useState} from 'react'
import { IoExtensionPuzzleSharp } from "react-icons/io5";
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom';
export default function CreateProjectCard({data,idx}) {
    const navigate=useNavigate()
    return (
        <motion.div onClick={()=>navigate("/project/"+data.id)} initial={{x:20}} animate={{x:0}} transition={{delay:idx*0.1,duration:0.2,animate:"smooth"}} whileHover={{y:-5}} className='bg-white p-6 border rounded-md shadow flex flex-col cursor-pointer hover:border-emerald-700'>
            <div className='w-full flex flex-col items-center justify-center'>
                <IoExtensionPuzzleSharp  className='w-24 h-24 text-emerald-700'/>
                <div className='flex flex-col items-center '>
                    <h3 className='p-1 px-2 rounded bg-amber-200 text-amber-700 text-xs font-semibold'>{data.topic}{data.subtopic&&` > ${data.subtopic}`}</h3>
                    <h6 className='text-md font-semibold'>{data.title}</h6>
                    <h6 className='text-gray-600 text-sm '>{data.subtitle}</h6>
                </div>
            </div>
        </motion.div>
    )
}
