import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CreateProjectModal from '../Components/CreateProjectModal'
import { MdAdd } from 'react-icons/md'
import toast from 'react-hot-toast'
import { getUserWithEmail } from '../API/users'
import { useAuth } from '../Hooks/AuthContext'
import {  ThreeCircles } from 'react-loader-spinner'
import { getUserProjects } from '../API/projects'
import CreateProjectCard from '../Components/ProjectHome/CreateProjectCard'
export default function ProjectHome() {
    const navigate=useNavigate()
    const user=useAuth()
    const [loading,setLoading]=useState(false)
    const [projects,setProjects]=useState([])
    const [loadingText,setLoadingText]=useState("Loading Projects..")
    const [showCreateProjectModal,setShowCreateProjectModal]=useState(false)
    const closeModal=()=>{
        setShowCreateProjectModal(false)
    }
    
    const getProjects = async()=>{
        setLoading(true)
        const user_id = localStorage.getItem("sal-generator-user")
        if(!user_id){
            //console.log("NO USER")
            const user_res = await getUserWithEmail(user.email)
            if(!user_res){
                navigate("/expert-profiling")
                return
            }
        }
        const res=await getUserProjects(user_id)
        setProjects(res)
        setLoading(false)

    }
    useEffect(()=>{
        getProjects()
    },[])
    if(loading){
        return(<div className='w-screen h-screen flex items-center justify-center'>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>{loadingText}</span></p>
        </div>)
    }
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6 bg-emerald-50 '>
    <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
        <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
            <FaAngleLeft/>
        </motion.button>
        <h2 className='text-md md:text-xl font-semibold ml-4'>My Projects </h2>

    </div>
    <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
        <motion.div  initial={{y:-20}} animate={{y:0}} transition={{duration:0.3,animate:"smooth"}} className='flex flex-col items-center'>
            <h2 className='text-left text-3xl md:text-4xl font-bold '>Manage <span className='text-emerald-700 '>Projects</span></h2>
            <p className='text-sm md:text-lg text-gray-600'>Create and manage your data collection projects</p>
        </motion.div>
        
        <div className='w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-8 mt-6 md:mt-12'>
            {!loading && projects.map((a,idx)=>(<CreateProjectCard data={a} idx={idx}/>))}
            {loading && <p>Loading Projects...</p>}
            {!loading && <motion.div onClick={()=>setShowCreateProjectModal(true)} initial={{x:20}} animate={{x:0}} transition={{delay:projects.length*0.1,duration:0.2,animate:"smooth"}} whileHover={{y:-5}} className='bg-white p-6 border rounded-md shadow flex flex-col items-center justify-center cursor-pointer hover:border-emerald-700'>
                <div className='w-full flex flex-col items-center justify-center'>
                    <MdAdd  className='w-24 h-24 text-gray-500'/>
                    <h6 className='text-md text-gray-500 font-semibold'>Create new project</h6>
                </div>
            </motion.div>}
        </div>
    </div>
    {showCreateProjectModal && <CreateProjectModal user_id={localStorage.getItem("sal-generator-user")} closeModal={closeModal}/>}
</div>
  )
}
