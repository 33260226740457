import {supabase} from '../supabaseClient'

export const getProjectFinetunes=async(project_id)=>{
    const res=await supabase
    .from('generator_finetune') 
    .select()
    .eq('project_id',project_id)
    //console.log(res.data)
    return res.data
}
export const getFinetuneByID=async(finetune_id)=>{
    //console.log(q_id)
    const res=await supabase
    .from('generator_finetune') 
    .select()
    .eq('id',finetune_id)
    //console.log(res.data)
    return res.data[0]
}
export const createProjectFinetune=async(data)=>{
    const res=await supabase
    .from('generator_finetune') 
    .insert([{
        "title":data.title,
        "subtitle":data.subtitle,
        "model":data.model,
        "project_id":data.project_id    
    }]).select();
    if(res.data){
        return res.data
    }
    return null
    
}

export const updateFinetuneTitle=async(new_title,ft_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_finetune')
            .update({ title: new_title })
            .eq('id', ft_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating finetune:', error);
        throw error;
    }
}
export const updateFinetuneSubtitle=async(new_subtitle,ft_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_finetune')
            .update({ subtitle: new_subtitle })
            .eq('id', ft_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating finetune:', error);
        throw error;
    }
}
export const updateFinetuneFileId=async(file_id,ft_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_finetune')
            .update({ file_id: file_id })
            .eq('id', ft_id);

        if (error) {
            throw error;
        }
        
        return data;
    } catch (error) {
        console.error('Error updating finetune:', error);
        throw error;
    }
}
export const updateFinetuneFTId=async(finetune_id,ft_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_finetune')
            .update({ finetune_id: finetune_id })
            .eq('id', ft_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating finetune:', error);
        throw error;
    }
}
export const updateFinetuneModelName=async(model_name,ft_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_finetune')
            .update({ model_name: model_name })
            .eq('id', ft_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating finetune:', error);
        throw error;
    }
}