import React,{useState} from 'react'
import { CgProfile } from 'react-icons/cg'
import {motion} from 'framer-motion'
import { useAuth } from '../Hooks/AuthContext'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
export default function ProfileNavIcon() {
    const navigate=useNavigate()
    const [open,setOpen]=useState(false)
    const {user,signOut}=useAuth()
    const logout=async()=>{
      await signOut()
      toast.success("Logout Successful!")
      setOpen(false)
    }
  return (
    <motion.div  className='fixed top-4 right-4 flex flex-col items-end'>
        <motion.button onClick={()=>setOpen(!open)} whileHover={{scale:1.05}} className='text-2xl p-2 rounded-full text-white bg-emerald-700'><CgProfile/></motion.button>
        {open && user && <ul className='bg-white rounded border shadow-md p-1 mt-2 mr-2'>
            <li onClick={()=>navigate("/expert-profile")} className='px-6 py-2 font-semibold text-md hover:bg-emerald-700 cursor-pointer hover:text-white'>My Profile</li>
            <hr/>
            <li onClick={()=>navigate("/projects")} className='px-6 py-2 font-semibold text-md hover:bg-emerald-700 cursor-pointer hover:text-white'>My Projects</li>
            <hr/>
            <li onClick={logout} className='px-6 py-2 font-semibold text-md hover:bg-emerald-700 cursor-pointer hover:text-white'>Logout</li>
        </ul>}
        {open && !user && <ul className='bg-white rounded border shadow-md p-1 mt-2 mr-2'>
            <li onClick={()=>navigate("/login")} className='px-6 py-2 font-semibold text-md hover:bg-emerald-700 cursor-pointer hover:text-white'>Login</li>
            
        </ul>}
    </motion.div>
  )
}
