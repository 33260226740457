import axios from 'axios';
import OpenAI from 'openai'
import { SERVER_URL } from '../Data/Endpoints';

export const askGPT=async(modelname,messages)=>{
    try {
        console.log("Sending to LLM ",messages)
        const response = await axios.post(SERVER_URL+"/ask-gpt",{
            model:modelname,
            messages:messages
        })
        //console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error calling GPT-4 API:', error);
        throw new Error('Error calling GPT-4 API');
    }
}
// export const askGPT=async(modelname,messages)=>{
//     try {
//         const response = await openai.chat.completions.create({
//             model: modelname,
//             messages: messages,
//             //max_tokens: 150,
//             //temperature: 0.7,
//         });
//         //console.log(response)
//         return response;
//     } catch (error) {
//         console.error('Error calling GPT-4 API:', error);
//         throw new Error('Error calling GPT-4 API');
//     }
// }
