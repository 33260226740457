import {supabase} from '../supabaseClient'

export const getProjectDatasets=async(project_id)=>{
    const res=await supabase
    .from('generator_dataset') 
    .select()
    .eq('project_id',project_id)
    //console.log(res.data)
    return res.data
}
export const getDatasetByID=async(dataset_id)=>{
    const res=await supabase
    .from('generator_dataset') 
    .select(`*,generator_dataset_row(*)`)
    .eq('id',dataset_id)
    .order('created_at', { foreignTable: 'generator_dataset_row', ascending: true });
    console.log(res.data[0])
    return res.data[0]
}


// export const getDatasetMessagesByID = async (dataset_id) => {
//     console.log("DATASET ID :",dataset_id)
//     const res = await supabase
//         .from('generator_dataset')
//         .select(`
//             *,
//             generator_dataset_row(
//                 *,
//                 generator_dataset_row_chat(*)
//             )
//         `)
//         .eq('id', dataset_id)
//         .order('created_at', { foreignTable: 'generator_dataset_row_chat' }); // Order generator_dataset_row_chat by created_at

//     return res.data[0];
// };

export const getDatasetMessagesByID = async (dataset_id) => {
    const res = await supabase
        .from('generator_dataset')
        .select(`
            *,
            generator_dataset_row(
                *,
                generator_dataset_row_chat(*)
            )
        `)
        .eq('id', dataset_id);

    // Sort generator_dataset_row_chat by created_at within each row
    if (res.data) {
        res.data[0].generator_dataset_row = res.data[0].generator_dataset_row.map(row => {
            row.generator_dataset_row_chat = row.generator_dataset_row_chat.sort((a, b) =>
                new Date(a.created_at) - new Date(b.created_at)
            );
            return row;
        });
    }

    return res.data[0];
};

export const createDataset=async(data)=>{
    const res=await supabase
    .from('generator_dataset') 
    .insert([{
        "title":data.title,
        "subtitle":data.subtitle,
        "project_id":data.project_id    
    }]).select();
    if(res.data){
        console.log(res.data)
        return res.data
    }
    return null
    
}
export const updateTitle=async(new_title,dataset_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_dataset')
            .update({ title: new_title })
            .eq('id', dataset_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
}
export const updateSubtitle=async(new_subtitle,dataset_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_dataset')
            .update({ subtitle: new_subtitle })
            .eq('id', dataset_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
}
export const updateQuestioningSystemPrompt=async(new_prompt,dataset_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_dataset')
            .update({ questioning_system_prompt: new_prompt })
            .eq('id', dataset_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
}
export const updateAnsweringSystemPrompt=async(new_prompt,dataset_id)=>{
    try {
        const { data, error } = await supabase
            .from('generator_dataset')
            .update({ answering_system_prompt: new_prompt })
            .eq('id', dataset_id);

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error updating message:', error);
        throw error;
    }
}