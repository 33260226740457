import React,{useState,useEffect} from 'react'
import { getDatasetRowQA } from '../../API/qa'
import {motion} from 'framer-motion'
import ReactMarkdown from 'react-markdown'
export default function DatasetRow({data}) {
  const [showDetails,setShowDetails]=useState(false)
  const [loading,setLoading]=useState(true)
  const [conversation,setConversation]=useState([])
  function formatTime(timeString) {
    const date = new Date(timeString); // Create a date object from the string
    if (isNaN(date.getTime())) {
        throw new Error("Invalid time string");
    }
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;

  }
  const getData=async()=>{
    const res=await getDatasetRowQA(data.id)
    setConversation(res.generator_dataset_row_chat)
    setLoading(false)
  }
  useEffect(()=>{
    getData()
  },[])
  return(
  <div onClick={()=>setShowDetails(!showDetails)} className='w-full p-4 cursor-pointer bg-white border hover:border-emerald-700'>
    <div className='w-full flex'>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>First Question</p><p className='text-sm text-justify'>{data.first_question}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Input Token Count</p><p className='text-2xl font-semibold text-center'>{data.total_input_tokens?data.total_input_tokens:"-"}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Output Token Count</p><p className='text-2xl font-semibold text-center'>{data.total_output_tokens?data.total_output_tokens:"-"}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Total Datapoints</p><p className='text-2xl font-semibold text-center'>{data.dataPoints?data.dataPoints:"-"}</p></div>
    </div>
    {showDetails && <motion.div initial={{scaleY:0}} animate={{scaleY:1}} className='mt-4'>
      <h2 className='text-md font-semibold bg-emerald-700 py-2 px-4 mb-2 text-white'>Full Conversation</h2>
      {!loading && conversation.map(convo=>(<div>
          {convo.type==="question" && <div className='mb-1'>
            <p>{formatTime(convo.created_at)}</p>
            <p className='px-2 text-md font-bold border-l-4 pb-2  border-gray-800'>Question<span className='text-xs px-2 py-1 bg-orange-300 rounded text-orange-700 ml-2'>{convo.model}</span></p>
            <p className='px-2 text-sm text-gray-600 border-l-4 border-gray-800'>{convo.content}</p>
            <div className='px-2 flex justify-between text-sm text-gray-800 bg-gray-100 py-1 border-l-4 border-gray-800'>
              {convo.input_tokens && <p >Input Tokens : <span>{convo.input_tokens?convo.input_tokens:"-"}</span></p>}
              {convo.output_tokens && <p >Output Tokens : <span>{convo.output_tokens?convo.output_tokens:"-"}</span></p>}
            </div>
          </div>}
          {convo.type==="answer" && <div className='mb-1'>
            <p>{formatTime(convo.created_at)}</p>
            <p className='px-2 text-md font-bold border-l-4 pb-2  text-emerald-700 border-emerald-700'>Answer <span className='text-xs px-2 py-1 bg-orange-300 rounded ml-2 text-orange-700'>{convo.model}</span></p>
            <ReactMarkdown className='px-2 text-sm text-gray-600 border-l-4 border-emerald-700'>{convo.content}</ReactMarkdown>
            <div className='px-2 flex justify-between text-sm text-gray-800 bg-gray-100 py-1 border-l-4 border-emerald-700'>
              {convo.input_tokens && <p >Input Tokens : <span>{convo.input_tokens}</span></p>}
              {convo.output_tokens && <p >Output Tokens : <span>{convo.output_tokens}</span></p>}
            </div>
          </div>}
      </div>))}
    </motion.div>}
  </div>)
}
