import React from 'react'
import { ThreeCircles } from 'react-loader-spinner';

export default function LoadingIndicator ({ message = "Gathering sources..." }){
    return (
      <div className="w-full max-w-7xl mx-auto flex items-center justify-center my-4 md:my-8">
        <ThreeCircles width={50} height={50} color="#047857" className="mr-4" />
        <p className="text-gray-700 ml-4">{message}</p>
       
       
      </div>
    );
}