import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import {  PiEmptyLight} from "react-icons/pi";

import { Circles, Oval, ThreeCircles } from 'react-loader-spinner'

import { getProjectQuestionSets, getQuestionsBySet } from '../../API/questions'
import { askGPT } from '../../Models/GPTCall';
import axios from 'axios'
import { createDatasetRow } from '../../API/qa';
import { generateAnsweringPrompt, generateQuestioningPrompt } from '../../Models/SystemPromptGenerator';
import LoadingIndicator from '../../Components/LoadingIndicator';
import toast from 'react-hot-toast';
import QuestioningPrompt from '../../Components/CreateFromQS/QuestioningPrompt';
import AnsweringPrompt from '../../Components/CreateFromQS/AnsweringPrompt';
import DatasetRow from '../../Components/MyDatasets/DatasetRow';
export default function CreateFromQuestionSet() {
  const navigate=useNavigate()
  const location=useLocation()
  const dataset_id=location.state.dataset_id
  const project_id=location.state.project_id
  const [formData,setFormData]=useState({})

  const [loading,setLoading]=useState(true)
  const [loadingSystemPrompt,setLoadingSystemPrompt]=useState(false)



  const [active,setActive]=useState("details")
  const [data,setData]=useState(null)
  const [questionSets,setQuestionSets]=useState([])
  const [selectedQuestionSet,setSelectedQuestionSet]=useState(null)

  const [selectedQuestionType,setSelectedQuestionType]=useState(null)
  const [selectedQuestionFormat,setSelectedQuestionFormat]=useState(null)
  const [selectedDifficulty,setSelectedDifficulty]=useState(null)
  const [selectedGoal,setSelectedGoal]=useState(null)
  const [selectedExpectedResponseType,setSelectedExpectedResponseType]=useState(null)
  const [selectedExpectedResponseSize,setSelectedExpectedResponseSize]=useState(null)
  const [conversationLevels,setConversationLevels]=useState(0)

  // const questionTypes=["Conceptual","Procedural","Reflective","Behavourial","Assessment"]
  // const difficulty=["Basic","Intermediate","Advanced","Expert"]
  // const questionFormat=["Open Ended","Scenario Based","Case Study","Reflection Prompts","Problem Solving"]
  // const pedagogicalGoal=["Knowledge Acquisition","Skill Development","Critical Thinking","Student Engagement"]
  // const expectedResponseType=["Descriptive answers","Strategies","Action Plans","Reflective Answers","Justifications","Feedback Provision"]
  // const expectedResponseSize=["Brief","Concise","Explanatory","Descriptive"]
   
  const questionTypes = ["Concept", "Procedure", "Reflection", "Behavior"]
  const difficulty = ["Easy", "Medium", "Hard"]
  const questionFormat = ["Open-ended", "Scenario", "Case Study", "Problem Solving"]
  const pedagogicalGoal = ["Knowledge", "Skills", "Critical Thinking", "Engagement"]
  const expectedResponseType = ["Short Answer", "Plan", "Reflection", "Explanation"]
  const expectedResponseSize = ["Brief", "Detailed"]


  const [conversation,setConversation]=useState([])
  const [doneConversation,setDoneConversation]=useState([])
  const [selectedModel,setSelectedModel]=useState("gpt-4o-mini")

  const [generatingQuestion,setGeneratingQuestion]=useState(false)
  const [generatingAnswer,setGeneratingAnswer]=useState(false)
  const [savingQA,setSavingQA]=useState(false)

  const [questionPrompt,setQuestionPrompt]=useState(null)
  const [answerPrompt,setAnswerPrompt]=useState(null)

  const [currentInputTokens,setCurrentInputTokens]=useState()
  const [currentOutputTokens,setCurrentOutputTokens]=useState()

  const [loaderMessage,setLoaderMessage]=useState("")
  const [startedGeneration,setStartedGeneration]= useState(false)
  const [completedGeneration,setCompletedGeneration] = useState(false)


  const changeQuesPrompt=(value)=>{
    setQuestionPrompt(value)
  }
  const changeAnsPrompt=(value)=>{
    setAnswerPrompt(value)
  }

  const getQuestionSets=async()=>{
    setLoading(true)
    const res = await getProjectQuestionSets(project_id)
    //console.log(dataset_id,project_id)
    if(res){
        setQuestionSets(res)
        //console.log(res)
        setLoading(false)
    }
}
    // const addQuestion=async(question)=>{
   
    //   const obj={
    //     type:"question",
    //     content:question,
    //     model:selectedModel,
    //     inputTokenCount:currentInputTokens,
    //     outputTokenCount:currentOutputTokens,
    //   }
    //   //console.log([...conversation,obj])
    //   await setConversation((conversation) => [...conversation,obj])
    //   console.log("ADDED ", conversation)
    // }
    // const addAnswer=async(answer)=>{
      
    //   const obj={
    //     type:"answer",
    //     content:answer,
    //     model:selectedModel,
    //     inputTokenCount:currentInputTokens,
    //     outputTokenCount:currentOutputTokens,
    //   }
    //   await setConversation((conversation) => [...conversation,obj])
    //   console.log("ADDED ", conversation)
     
    // }
    const createQuestionWithOllama=async(convos)=>{
      
      setGeneratingQuestion(true)
      const convo=[{   
        role:"system",
        content:questionPrompt
      }]
      convos.map(a=>{
        convo.push({
          role:a["type"]==="question"?"assistant":"user",
          content:a["content"]
        })
      })
      
      let question, inputTokens, outputTokens;

      if(selectedModel==="gpt-4o" || selectedModel ==="gpt-4o-mini"){
        const res=await askGPT(selectedModel,convo)
        inputTokens = (res.usage.prompt_tokens)
        outputTokens = res.usage.completion_tokens;
        question = res.choices[0].message.content
      }else{
        const endpoint = "http://localhost:11434/api/chat"
        const res = await axios.post(endpoint,{
          model:selectedModel,
          messages:convo,
          stream:false
        })
        //console.log("QUES : ",res.data)
        //console.log("END")
        inputTokens = (res.data.prompt_eval_count)
        outputTokens = (res.data.eval_count)
        question = res.data.message.content
      }
      
      setGeneratingQuestion(false);
      setCurrentInputTokens(inputTokens);
      setCurrentOutputTokens(outputTokens);
    
      return { question, inputTokens, outputTokens };
      
    }
    const createAnswerWithOllama = async (convos) => {
      setGeneratingAnswer(true);
      const convo = [{   
        role: "system",
        content: answerPrompt
      }];
    
      convos.map(a => {
        convo.push({
          role: a["type"] === "question" ? "user" : "assistant",
          content: a["content"]
        });
      });
    
      let answer, inputTokens, outputTokens;
    
      if (selectedModel === "gpt-4o" || selectedModel === "gpt-4o-mini") {
        const res = await askGPT(selectedModel, convo);
        answer = res.choices[0].message.content;
        inputTokens = res.usage.prompt_tokens;
        outputTokens = res.usage.completion_tokens;
      } else {
        const endpoint = "http://localhost:11434/api/chat";
        const res = await axios.post(endpoint, {
          model: selectedModel,
          messages: convo,
          stream: false
        });
        answer = res.data.message.content;
        inputTokens = res.data.prompt_eval_count;
        outputTokens = res.data.eval_count;
      }
    
      setGeneratingAnswer(false);
      setCurrentInputTokens(inputTokens);
      setCurrentOutputTokens(outputTokens);
    
      return { answer, inputTokens, outputTokens };
    };
    const countTotalInputToken = (conv) => {
      return conv.reduce((total, a) => total + (a.inputTokenCount || 0), 0);
    }
    
    const countTotalOutputToken = (conv) => {
      return conv.reduce((total, a) => total + (a.outputTokenCount || 0), 0);
    }
    
    // Modified submitQA function
    const submitQA = async (currentConversation) => {
      setSavingQA(true);
      console.log(currentConversation);
  
      let ipTokens = countTotalInputToken(currentConversation);
      let opTokens = countTotalOutputToken(currentConversation);
  
      console.log(ipTokens);
      console.log(opTokens);
  
      try {
          const res = await createDatasetRow(currentConversation, dataset_id, ipTokens, opTokens);
  
          // Update the state
          setDoneConversation((prevDoneConversation) => {
              const updated = [...prevDoneConversation, res];
              console.log("Updated Done Conversation:", updated);
              return updated;
          });
      } catch (error) {
          console.error("Error creating dataset row:", error);
      } finally {
          setSavingQA(false);
      }
  };
  
    // const submitQA = async (currentConversation) => {
    //   setSavingQA(true);
    //   console.log(currentConversation);
    //   let ipTokens = countTotalInputToken(currentConversation);
    //   let opTokens = countTotalOutputToken(currentConversation);
    //   console.log(ipTokens);
    //   console.log(opTokens);
    //   const res = await createDatasetRow(currentConversation, dataset_id, ipTokens, opTokens);
    //   setDoneConversation((doneConversation) => [...doneConversation, res]);
    //   console.log("Done Conversation",doneConversation)
    //   setSavingQA(false);
    // };

    const generateDataset = async () => {
      if(conversationLevels<=0){
        toast.error("Conversation levels should be greater than 0")
        return
      }
      setStartedGeneration(true)
      const res = await getQuestionsBySet(selectedQuestionSet.id);
      for (const q of res) {
        let currentConversation = [];
    
        // Log and add the initial question
        console.log(q.question);
        const obj = {
          type: "question",
          content: q.question,
          model: selectedModel,
          inputTokenCount: 0,  // We'll update this later
          outputTokenCount: 0, // We'll update this later
        };
    
        currentConversation = [...currentConversation, obj];
        await new Promise((resolve) => {
          setConversation(currentConversation);
          console.log("Added question:", currentConversation);
          resolve();
        });
    
        // Generate and log the initial answer
        const { answer: ans, inputTokens, outputTokens } = await createAnswerWithOllama(currentConversation);
        const obj2 = {
          type: "answer",
          content: ans,
          model: selectedModel,
          inputTokenCount: inputTokens,
          outputTokenCount: outputTokens,
        };
    
        // Update the question's token counts
        currentConversation[0].inputTokenCount = inputTokens;
        currentConversation[0].outputTokenCount = outputTokens;
    
        currentConversation = [...currentConversation, obj2];
        await new Promise((resolve) => {
          setConversation(currentConversation);
          console.log("Added answer:", currentConversation);
          resolve();
        });
    
        // Create additional conversation levels
        const numbersArray = Array.from({ length: conversationLevels - 1 }, (_, i) => i + 1);
        for (const i of numbersArray) {
          // Generate and log the follow-up question
          const { question: qs, inputTokens: qInputTokens, outputTokens: qOutputTokens } = await createQuestionWithOllama(currentConversation);
          console.log(i, qs);
        
          const questionObj = {
            type: "question",
            content: qs,
            model: selectedModel,
            inputTokenCount: qInputTokens,
            outputTokenCount: qOutputTokens,
          };
        
          // Make a local copy of the conversation to avoid unsafe references
          let newConversation = [...currentConversation, questionObj];
          await new Promise((resolve) => {
            setConversation(newConversation);
            console.log("Added question:", newConversation);
            resolve();
          });
        
          // Generate and log the follow-up answer
          const { answer: as, inputTokens: aInputTokens, outputTokens: aOutputTokens } = await createAnswerWithOllama(newConversation);
          console.log(i, as);
        
          const answerObj = {
            type: "answer",
            content: as,
            model: selectedModel,
            inputTokenCount: aInputTokens,
            outputTokenCount: aOutputTokens,
          };
        
          // Update the local copy again
          newConversation = [...newConversation, answerObj];
          await new Promise((resolve) => {
            setConversation(newConversation);
            console.log("Added answer:", newConversation);
            resolve();
          });
        
          // Finally, assign the local copy to the external variable
          currentConversation = newConversation;
        }
        
        // Call submitQA() after processing each question, passing the current conversation
        await new Promise((resolve) => {
          submitQA(currentConversation);
          //console.log("Added answer:", currentConversation);
          resolve();
        });
        
      }
      setCompletedGeneration(true)
    };
  const findSystemPrompts=async()=>{
    if(!selectedQuestionType || !selectedDifficulty || !selectedQuestionFormat || !selectedGoal || !selectedExpectedResponseType || !selectedExpectedResponseSize){
      toast.error("Select all the fields first!")
      return
    }
    setLoadingSystemPrompt(true)
    const res=await generateQuestioningPrompt(selectedQuestionType,selectedDifficulty,selectedQuestionFormat,selectedGoal)
    setQuestionPrompt(res)
    const res2=await generateAnsweringPrompt(selectedQuestionType,selectedDifficulty,selectedQuestionFormat,selectedGoal)
    setAnswerPrompt(res2)
    setLoadingSystemPrompt(false)
  }
  useEffect(()=>{
    getQuestionSets()
  },[])

  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center'>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Dataset...</span></p>
    </div>)
  } 
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Dataset Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32 mb-4'>
          <h2 className='text-left text-3xl font-bold '>Create Dataset <span className='text-emerald-700 '>From Questionsets</span></h2>
          <p className='text-sm font-gray-600'>Choose a questionset and generate dataset from it</p>
      </div>
      
      {<div className='w-full max-w-7xl flex flex-col items-center p-8 md:p-12 bg-gray-100 border rounded shadow-md'>
        <h2 className='text-2xl text-center font-semibold text-emerald-700 mb-4'>Choose Question Set</h2>
        {questionSets.length===0 &&<div className='p-6 bg-white rounded border w-full flex flex-col items-center'>
            <PiEmptyLight className='text-5xl text-gray-500 mb-2'/>
            <p className='text-sm text-gray-500'>No question-sets available</p>
            
        </div>}
        <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4'>
          {questionSets.map(a=>(<motion.div whileHover={{translateY:"-5px"}} onClick={()=>setSelectedQuestionSet(a)} className={`p-8 cursor-pointer rounded border hover:border-2  ${selectedQuestionSet!==a ?"bg-white hover:border-emerald-700":"bg-emerald-700"}`}>
              <p className={`text-xl font-semibold text-left ${selectedQuestionSet!==a ? "text-emerald-700":"text-white"}`}>{a.title}</p>
              <p className={`text-sm text-left  ${selectedQuestionSet!==a ? "text-gray-600":"text-white"}`}>{a.subtitle}</p>
              
          </motion.div>))}
        </div>
      </div>}
      {selectedQuestionSet  && !startedGeneration && <div className='w-full max-w-7xl flex flex-col items-center p-8  bg-gray-100 border rounded shadow-md my-2'>
        {questionPrompt && answerPrompt &&  <div className="w-full max-w-7xl p-6 ">
          <h3 className="text-center text-emerald-700 font-semibold text-xl">Start Generating Dataset</h3>
          <p className="mb-4 text-sm text-center text-gray-500">(Once you're done with choosing the conversation levels and the prompts are finalized, start generating dataset QA)</p>
          <div>
            <label className="block text-lg text-emerald-700 font-semibold">
              Conversation Levels
            </label>
            <input
                type="number"
                name="conversation_levels"
                value={conversationLevels}
                onChange={(e)=>setConversationLevels(e.target.value)}
                placeholder="Conversation Levels"
                className="w-full text-sm p-3 border border-gray-300 rounded-lg"
            />
          </div>
        </div>}
        {(!questionPrompt || !answerPrompt) && !startedGeneration && <div className="w-full max-w-7xl p-6 ">
            <h3 className="text-center text-emerald-700 font-semibold text-xl">Generate Prompts with Settings</h3>
            <p className="mb-4 text-sm text-center text-gray-500">(These fields help your prompts to be more specific and produce better quality answers)</p>
            <div>
                <label className="block text-lg text-emerald-700 font-semibold">
                  Questioning Agent
                </label>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Questioning Type
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {questionTypes.map(a=>(
                            <button onClick={()=>setSelectedQuestionType(a)} className={`p-4 border rounded-md shadow ${selectedQuestionType===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Difficulty Level
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
                        {difficulty.map(a=>(
                            <button onClick={()=>setSelectedDifficulty(a)} className={`p-4 border rounded-md shadow ${selectedDifficulty===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Question Format
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {questionFormat.map(a=>(
                            <button onClick={()=>setSelectedQuestionFormat(a)} className={`p-4 border rounded-md shadow ${selectedQuestionFormat===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Learning Goal
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {pedagogicalGoal.map(a=>(
                            <button onClick={()=>setSelectedGoal(a)} className={`p-4 border rounded-md shadow ${selectedGoal===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <label className="block text-lg text-emerald-700 font-semibold">
                  Answering Agent
                </label>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Expected Answer Type
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                        {expectedResponseType.map(a=>(
                            <button onClick={()=>setSelectedExpectedResponseType(a)} className={`p-4 border rounded-md shadow ${selectedExpectedResponseType===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="bg-white rounded border shadow-md p-4 my-2">
                    <label className="block text-lg text-gray-700 font-semibold mb-2">
                        Expected Answer Type
                    </label>
                    <div className='grid grid-cols-2 md:grid-cols-2 gap-4'>
                        {expectedResponseSize.map(a=>(
                            <button onClick={()=>setSelectedExpectedResponseSize(a)} className={`p-4 border rounded-md shadow ${selectedExpectedResponseSize===a && "bg-emerald-700 text-white"}`}>
                                <p className='text-sm text-center'>{a}</p>
                            </button>
                        ))}
                    </div>
                </div>
                
            </div>
           

          
        </div>}
       {questionPrompt && answerPrompt &&  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
          <QuestioningPrompt change={changeQuesPrompt} value={questionPrompt}/>
          <AnsweringPrompt change={changeAnsPrompt} value={answerPrompt}/>           
        </div>}
        
        
        {(!questionPrompt || !answerPrompt) && <button onClick={findSystemPrompts} className='font-semibold text-lg px-4 py-2 bg-emerald-700 text-white hover:bg-emerald-800 rounded shadow'>{loadingSystemPrompt ?<div className="flex items-center space-x-2"><Oval color="#f2f2f2" height={20} width={20}/> Generating System Prompt...</div >: "Generate System Prompts"}</button>}
        {questionPrompt && answerPrompt && <button onClick={generateDataset} className='font-semibold text-lg px-4 py-2 bg-emerald-700 text-white hover:bg-emerald-800 rounded shadow'>Generate Dataset</button>}
       
      </div>}
      {startedGeneration && <div className='w-full max-w-7xl flex flex-col items-center p-8  bg-gray-100 border rounded shadow-md my-2'>
        {generatingQuestion && <LoadingIndicator message='Generating Question...'/>}
          {generatingAnswer && <LoadingIndicator message='Generating Answer...'/>}
          {savingQA && <LoadingIndicator message='Saving QA ...'/>}
        {completedGeneration && <h2>Completed Generation</h2>}
          {doneConversation && doneConversation.map(a=>{
            a.map(b=>(<DatasetRow data={b}/>))
          })}
      </div>}
      
    </div>
  )
}
