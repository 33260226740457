import React,{useState,useEffect} from 'react'
import {updateTitle ,updateSubtitle, getDatasetByID, getProjectDatasets, getDatasetMessagesByID} from '../../API/datasets'
import { MdDone, MdEdit } from 'react-icons/md'
import PromptCreatorModal from '../PromptCreatorModal'
import toast from 'react-hot-toast'
import { getFinetuneByID, updateFinetuneFileId, updateFinetuneFTId, updateFinetuneModelName } from '../../API/finetune'
import { ThreeCircles } from 'react-loader-spinner'
import { getDatasetRowQA } from '../../API/qa'
import { motion } from 'framer-motion/client'
import Papa from 'papaparse';
import axios from 'axios'
import { SERVER_URL } from '../../Data/Endpoints'
export default function FinetuneProcess({data,refreshFT}) {
    const [datasets,setDatasets]=useState(null)
    const [selectedDataset,setSelectedDataset] = useState(null)
    const [status,setStatus]=useState(null)
    const [loading,setLoading]=useState(true)
    const [loadingFileUpload,setLoadingFileUpload]=useState(false)
    const [loadingProcessing,setLoadingProcessing]=useState(false)
    
    const convertDatasetToCSV = async() => {
        setLoadingFileUpload(true)
        try{
            const dataset = await getDatasetMessagesByID(selectedDataset)
            // Flatten and structure the data to be CSV-ready
            const rowSets=[]

            // Extract necessary data from the JSON structure
            dataset.generator_dataset_row.forEach(row => {
                const rows = {};
                let quesCount=0
                let ansCount=0
                row.generator_dataset_row_chat.forEach((chat,idx) => {
                    if(chat.type==="question"){
                        quesCount+=1
                        rows[chat.type+(quesCount)] = chat.content
                    }else{
                        ansCount+=1
                        rows[chat.type+(ansCount)] = chat.content
                    }
                    //rows[chat.type+(idx+1)] = chat.content
                        //chat_content: chat.content,
                    
                });
                console.log("ROW : ",rows)
                rowSets.push(rows);
            });
            console.log(rowSets)

            // Convert the structured rows to CSV using PapaParse
            const csv = Papa.unparse(rowSets);
            //console.log(csv)
            // Create a Blob from the CSV string
            const blob = new Blob([csv], { type: 'text/csv' });

            // Prepare the FormData object
            const formData = new FormData();
            formData.append('file', blob, 'dataset.csv');

            const res= await axios.post(SERVER_URL+"/upload-csv/",formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }})
            console.log(res)
            if(res.status===200){
                const file_id=res.data.file_id
                console.log(file_id)
                const ftRes= await updateFinetuneFileId(file_id,data.id)
                toast.success("Dataset uploaded successfully")
                // if(ftRes){
                //     toast.success("Dataset uploaded successfully")
                // }else{
                //     throw Error("Couldn't update finetune with file_id")
                // }
                
                
            }
            console.log(res)
        }catch(e){
            toast.error("Couldn't convert dataset to CSV")
            console.log("Error converting dataset to CSV",e)
        }
        setLoadingFileUpload(false)
    }
    const startFinetuning = async()=>{ 
        setLoadingProcessing(true) 
        try{
            const res = await axios.post(SERVER_URL+"/fine-tune/",{
                file_id:data.file_id,
                model:"gpt-4o-mini-2024-07-18"
            })
            //console.log(res)
            const fineTuneID= res.data.fine_tune_id
            const ftRes= await updateFinetuneFTId(fineTuneID,data.id)
            if(ftRes){
                refreshFT()
                toast.success("Finetuning started successfully")
            }
        }catch(e){
            toast.error("Couldn't start finetuning")
            console.log(e)
        }
        setLoadingProcessing(false)
    }
    const getDatasets = async()=>{
        try{
            const res = await getProjectDatasets(data.project_id)
            //console.log(res)
            setDatasets(res)
        }catch(e){
            toast.error("Couldn't fetch project datasets")
            console.log("Error fetching project datasets",e)
        }
        
    }
    const getFinetuneDetails = async()=>{
        try{
            //Getting status if finetuning started
            if(data.finetune_id){
                const status = await axios.get(SERVER_URL+"/fine-tune-status/"+data.finetune_id)
                setStatus(status.data.status)
                //console.log(status.data)
                //Updating model_name if finetune complete
                if(!data.model_name){
                    const update = await updateFinetuneModelName(status.data.fine_tuned_model,data.id)
                    console.log(update)
                }
                
            }
        }catch(e){
            toast.error("Couldn't fetch finetune details")
            console.log("Error fetching finetune details",e)
        }
        //setLoading(false)
    }
   
    const getAll = async()=>{
        await getFinetuneDetails()
        if(!data.file_id){
            await getDatasets()
        }
        setLoading(false)
        
    }
    useEffect(()=>{
        getAll()
    },[])
    if(loading){
        return(<div className='flex items-center justify-center '>
            <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Finetuning...</span></p>
        </div>)
      } 
    return(<div className='w-full space-y-8'>
      <div className='w-full flex items-end'>
        {!data.file_id && <div className='w-full border-2 border-dashed border-red-500 bg-red-50 p-6 flex flex-col items-center justify-center'>
            <h2 className='text-lg font-semibold'>You haven't uploaded a dataset to finetune on yet</h2>
            <p className='text-sm text-gray-500 mb-3'>Choose a dataset and upload to continue finetuning</p>
            <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 my-4'>
                {datasets.map(a=>(<div onClick={()=>setSelectedDataset(a.id)} className={`w-full p-6 border shadow rounded cursor-pointer hover:border-emerald-700 hover:shadow-lg ${a.id===selectedDataset ? "bg-emerald-700 text-white":"bg-white"}`}>
                    <p className='font-semibold'>{a.title}</p>
                    <p className='text-sm'>{a.subtitle}</p>
                </div>))}
            </div>
            {selectedDataset && <button onClick={convertDatasetToCSV} className='px-4 py-2 bg-emerald-700 rounded text-sm font-semibold text-white hover:bg-emerald-800'>{loadingFileUpload?"Uploading...(This might take a while)":"Upload Dataset for Finetuning"}</button>}
        </div>}
        {data.file_id && !data.finetune_id && <div className='w-full border-2 border-amber-500 bg-amber-50 border-dashed p-6 flex flex-col items-center justify-center'>
            <h2 className='text-lg font-semibold'>Your finetune hasn't been processed</h2>
            <p className='text-sm text-gray-500 mb-3'>Start processing your finetuning to start training the model</p>
            <button onClick={startFinetuning} className='px-4 py-2 bg-emerald-700 rounded text-sm font-semibold text-white hover:bg-emerald-800'>{loadingProcessing?"Processing...":"Click to start processing"}</button>
        </div>}
        {data.finetune_id && <div className='w-full border-2 border-green-500 bg-green-50 border-dashed p-6 flex flex-col items-center justify-center'>  
            {status==="succeeded" && <div className='p-2 bg-emerald-700 rounded-full text-white'><MdDone className='w-12 h-12 '/></div>}
            {status!=="succeeded" && <div className='p-2 rounded-full text-white'><ThreeCircles className='w-12 h-12 text-emerald-600'/></div>}
            <h2 className='text-lg font-semibold'>{status==="succeeded"?"Your finetune has completed!":"Your finetune is being processed.."}</h2>
            <p className='text-sm text-gray-500 mb-3'>{status==="succeeded"?"Your can chat with your model now":"Track your finetuning"}</p>

        </div>}
      </div>

      
    </div>)
}
