import React,{useState} from 'react'
import { FaAngleLeft } from 'react-icons/fa'
import { MdDone,MdEdit } from 'react-icons/md'
import {motion} from 'framer-motion'
import { ThreeCircles } from 'react-loader-spinner'
import Papa from 'papaparse'
import toast from 'react-hot-toast'
import { createSetQuestion } from '../../API/questions'
import { useLocation } from 'react-router-dom'
import PaginatedQuestions from '../../Components/CreateFromQS/QuestionSetCreation/PaginatedQuestions'
const QuestionComponent =({ques,addQuestion})=>{
  const [added,setAdded]=useState(false)
  const clicked=()=>{
      setAdded(true)
      console.log("clik")
      addQuestion()
      
  }
  return(
  <button onClick={clicked} className='w-full cursor-pointer bg-white border my-1 hover:border-emerald-700 hover:bg-gray-100 max-w-7xl shadow-lg p-4 flex items-center justify-between'>
     
      <p className='text-sm :md:text-md mb-2'>{ques}</p>
      {added && <p className='text-xl'>✅</p>}
  </button>
  )
}

export default function CreateFromCSV() {
  const location=useLocation()
  const questionset_id=location.state.questionset_id
  const [loading,setLoading]=useState(false)
  const  [uploading,setUploading] = useState(false)
  const [CSVData,setCSVData]=useState([])
  const [CSVCols,setCSVCols]=useState([])
  const [questionField,setQuestionField]=useState(null)
  const [questionDone,setQuestionDone]=useState(null)
  const [questions,setQuestions]=useState([])
  const [selectedQuestions,setSelectedQuestions]=useState([])
  const uploadQuestions = async () => {
    setUploading(true);
    //console.log(selectedQuestions)
    setQuestions([])
    if (!selectedQuestions || selectedQuestions.length === 0) {
      toast.error("You haven't selected any questions!");
      setUploading(false);
      return;
    }
  
    try {
      const uploadPromises = selectedQuestions.map(async (ques) => {
        const res = await createSetQuestion({
          "question": ques,
          "set_id": questionset_id
        });
        return res;
      });
  
      const results = await Promise.all(uploadPromises);
      toast.success("Successfully uploaded questions");
    } catch (error) {
      //console.error("Error uploading questions:", error);
      toast.error("Failed to upload questions. Please try again.");
    } finally {
        window.history.back()
        setQuestionField(null)
        setQuestions(false)
        setUploading(false);
    }
  };
  const fileUploadHandler=(e)=>{
    setSelectedQuestions([])
    setQuestions([])
    setQuestionField("")
    Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setCSVData(results.data);
          setCSVCols(results.meta.fields);
        },
      });
  }
  const generateData=(fieldName)=>{
    let finalData=[]
    CSVData.forEach((a)=>{
      finalData.push(a[fieldName])
    })
    console.log(finalData)
    setQuestions(finalData)
  }
  
  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center'>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Dataset...</span></p>
    </div>)
  } 
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6 bg-emerald-50'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Dataset Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32 mb-4'>
          <h2 className='text-left text-xl md:text-3xl font-bold '>Create Dataset <span className='text-emerald-700 '>From CSV</span></h2>
          <p className='text-sm font-gray-500'>Choose a CSV and generate dataset from it</p>
      </div>

      <div className='w-full max-w-7xl p-6 md:p-12 bg-white rounded border shadow'>
        {/** FILE UPLOAD */}
        <div className='w-full flex flex-col md:flex-row items-start md:items-center justify-between my-2'>
            <div className='text-left mb-2 md:mb-0'>
                <p className='text-lg font-semibold'>Upload CSV</p>
                <p className='text-xs md:text-md text-gray-600'>Upload the CSV file you want to evaluate using</p>
            </div>
            <label class="inline-flex items-center cursor-pointer">
                <input type="file" accept=".csv" onChange={(e)=>fileUploadHandler(e)}/>

            </label>
        </div>
       {CSVCols.length>0 && <div className='w-full mt-4 md:mt-0 flex flex-col md:flex-row items-start md:items-center justify-between my-2 space-y-3'>
            <div className='text-left mb-2 md:mb-0'>
                <p className='text-lg font-semibold'>Question Field</p>
                <p className='text-xs md:text-md text-gray-600'>Choose the field name of the question column in your CSV file. </p>
            </div>
            <select
              value={questionField}
              onChange={(e) => {
                setQuestionField(e.target.value)
                generateData(e.target.value)
              }}
              className="h-12 border border-gray-400 px-2"
            >
              <option value="">Select a field</option>
              {CSVCols.map(a=>(<option key={a} value={a}>{a}</option>))}
            </select>
        </div>}
      </div>

      {questions.length>0 && <div className='w-full max-w-7xl p-6 border shadow rounded mt-4 bg-white'>
          <div className='w-full flex items-center justify-between'>
            <div>
              <h4 className=" w-full max-w-7xl text-2xl font-bold text-left text-emerald-700 "> Generated Questions </h4>
              <h4 className=" w-full max-w-7xl text-sm text-left text-gray-500 mb-4">Select and upload questions to add them to your questionset</h4>
            </div>
            <button onClick={()=>{
              if(selectedQuestions.length === questions.length){
                setSelectedQuestions([])
              }else{
                setSelectedQuestions(questions)
              }
              
            }} className='border rounded border-emerald-700 text-sm text-center hover:bg-emerald-100 text-emerald-700 font-semibold px-4 py-2'>{(selectedQuestions.length === questions.length)?"Deselect all":"Select All"}</button> 
          </div>
          <PaginatedQuestions questions={questions} selectedQuestions={selectedQuestions} setSelectedQuestions={setSelectedQuestions}/>
          <button onClick={uploadQuestions} className='w-full max-w-7xl mt-8 bg-emerald-700 text-center hover:bg-emerald-800 text-white font-semibold px-4 py-2'>Add to question set</button> 
        </div>}

      
    </div>
  )
}
