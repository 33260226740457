import React, { useState } from "react";

const QuestionComponent =({ques,selectedQuestions,handleQuestionClick})=>{
    const clicked=()=>{
        handleQuestionClick()
    }
    return(
    <div onClick={clicked} className='w-full cursor-pointer border hover:border-emerald-700 hover:bg-gray-100 max-w-7xl shadow-lg p-4 flex items-center justify-start'>
        {selectedQuestions.includes(ques) && <p className='text-xl mr-2'>✅</p>}
        <p className='text-sm md:text-md'>{ques}</p>
        
    </div>
    )
}

const PaginatedQuestions = ({ questions,selectedQuestions, setSelectedQuestions }) => {
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7; // Number of items to show per page
    const maxPageButtons = 5; // Maximum number of page buttons to show
  
    // Calculate total pages
    const totalPages = Math.ceil(questions.length / itemsPerPage);
  
    // Get the current page's data
    const currentQuestions = questions.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  
    // Handle page change
    const goToNextPage = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
  
    const goToPrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const goToFirstPage = () => {
      setCurrentPage(1);
    };
  
    const goToLastPage = () => {
      setCurrentPage(totalPages);
    };
  
    const setPage = (page) => {
      setCurrentPage(page);
    };
  
    // Generate dynamic pagination range
    const getPaginationRange = () => {
      const totalButtons = Math.min(maxPageButtons, totalPages);
      let startPage = Math.max(1, currentPage - Math.floor(totalButtons / 2));
      let endPage = startPage + totalButtons - 1;
  
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - totalButtons + 1);
      }
  
      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };
  
    return (
      <div className="container mx-auto">
        {/* Render the QuestionComponent for current questions */}
        <ul className="space-y-2">
          {currentQuestions.map((a, idx) => (
            <li key={idx}>
              <QuestionComponent
                ques={a}
                selectedQuestions={selectedQuestions}
                handleQuestionClick={() =>{
                  if(!selectedQuestions.includes(a)){
                    setSelectedQuestions(selectedQuestions=>[...selectedQuestions,a])
                  }
                  if(selectedQuestions.includes(a)){
                    setSelectedQuestions(selectedQuestions=>selectedQuestions.filter(item=>item!==a))
                  }
                  //console.log(selectedQuestions)
                  
                }}
              />
            </li>
          ))}
        </ul>
  
        {/* Pagination Controls */}
        <div className="mt-6 flex justify-center space-x-4 mb-4">
          {/* First and Previous */}
          <button
            onClick={goToFirstPage}
            disabled={currentPage === 1}
            className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
          >
            First
          </button>
  
          <button
            onClick={goToPrevPage}
            disabled={currentPage === 1}
            className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
          >
            Previous
          </button>
  
          {/* Page numbers */}
          {getPaginationRange().map((page) => (
            <button
              key={page}
              onClick={() => setPage(page)}
              className={`px-3 py-2 ${
                currentPage === page
                  ? "bg-emerald-700 text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              } rounded-md`}
            >
              {page}
            </button>
          ))}
  
          {/* Ellipsis if not on the last page */}
          {currentPage < totalPages - Math.floor(maxPageButtons / 2) && (
            <span className="px-3 py-2">...</span>
          )}
  
          {/* Next and Last */}
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
          >
            Next
          </button>
  
          <button
            onClick={goToLastPage}
            disabled={currentPage === totalPages}
            className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>
    );
  };

export default PaginatedQuestions;