import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { ThreeCircles } from 'react-loader-spinner'
import { GrDocumentCsv,GrDocumentPdf } from "react-icons/gr";
import { MdOutlineCreate, MdOutlineLibraryAdd } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { getQuestionsBySet, getQuestionsetByID } from '../API/questions'
import QuestionsetDetailsForm from '../Components/QuestionsetDetailsForm'
import { BiDetail } from "react-icons/bi";
import { BsQuestionDiamondFill } from "react-icons/bs";
const QuestionRow =({data})=>{
    return (
        <div className='w-full p-2 bg-gray-50 my-1 border flex flex-col-reverse rounded items-start justify-between'>
            <p className='text-sm md:text-lg'>{data.question}</p>
            {data.used && <div className='px-3 py-1 bg-green-200 text-green-700 text-xs font-bold rounded'><p>USED</p></div>}
            {!data.used && <div className='px-3 py-1 bg-red-200 text-red-700 text-xs font-bold rounded'><p>NOT USED</p></div>}
            
        </div>
    )
}
const PaginatedQuestions = ({ questions }) => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7; // Number of items to show per page
  const maxPageButtons = 5; // Maximum number of page buttons to show

  // Calculate total pages
  const totalPages = Math.ceil(questions.length / itemsPerPage);

  // Get the current page's data
  const currentQuestions = questions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  const setPage = (page) => {
    setCurrentPage(page);
  };

  // Generate dynamic pagination range
  const getPaginationRange = () => {
    const totalButtons = Math.min(maxPageButtons, totalPages);
    let startPage = Math.max(1, currentPage - Math.floor(totalButtons / 2));
    let endPage = startPage + totalButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalButtons + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return (
    <div className="container mx-auto">
      {/* Render the QuestionComponent for current questions */}
      <ul className="space-y-2">
        {currentQuestions.map((a, idx) => (
          <li key={idx}>
            <QuestionRow
              data={a}
            />
          </li>
        ))}
      </ul>

      {/* Pagination Controls */}
      <div className="mt-6 flex justify-center space-x-4 mb-4">
        {/* First and Previous */}
        <button
          onClick={goToFirstPage}
          disabled={currentPage === 1}
          className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
        >
          First
        </button>

        <button
          onClick={goToPrevPage}
          disabled={currentPage === 1}
          className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
        >
          Previous
        </button>

        {/* Page numbers */}
        {getPaginationRange().map((page) => (
          <button
            key={page}
            onClick={() => setPage(page)}
            className={`px-3 py-2 ${
              currentPage === page
                ? "bg-emerald-700 text-white"
                : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            } rounded-md`}
          >
            {page}
          </button>
        ))}

        {/* Ellipsis if not on the last page */}
        {currentPage < totalPages - Math.floor(maxPageButtons / 2) && (
          <span className="px-3 py-2">...</span>
        )}

        {/* Next and Last */}
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
        >
          Next
        </button>

        <button
          onClick={goToLastPage}
          disabled={currentPage === totalPages}
          className="px-3 py-2 bg-gray-200 text-gray-600 hover:bg-gray-300 disabled:hover:bg-gray-200 rounded-md disabled:opacity-50"
        >
          Last
        </button>
      </div>
    </div>
  );
};
export default function MyQuestionsets() {
  const navigate=useNavigate()
  const location=useLocation()
  const questionset_id=location.state.question_set

  const [loading,setLoading]=useState(true)
  const [active,setActive]=useState("details")
  const [data,setData]=useState(null)
  const [questions,setQuestions]=useState(null)


  const getQuestionsetDetails=async()=>{
    const res=await getQuestionsetByID(questionset_id)
    setData(res)
    setLoading(false)
  }
  const getAllQuestions=async()=>{
    setLoading(true)
    const res=await getQuestionsBySet(questionset_id)
    setQuestions(res)
    setLoading(false)
  }
  const getAll= async()=>{
    await getQuestionsetDetails()
    await getAllQuestions()
  }
  useEffect(()=>{
    getAll()
  },[])

  if(loading){
    return(<div className='w-screen h-screen flex items-center justify-center'>
        <p className='flex items-center space-x-4 text-md font-semibold text-gray-700'><ThreeCircles width={50} height={50} color="#047857"/> <span>Loading Dataset...</span></p>
    </div>)
  } 
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6 bg-emerald-50'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Question Set Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
          <h2 className='text-left text-3xl font-bold '>Manage <span className='text-emerald-700 '>Question Set</span></h2>
          <p className='text-sm font-gray-600'>View, edit and export your question set</p>
      </div>
      <div className="w-full flex items-center justify-center p-6 ">
        {active==="details" && <button className='px-4 py-2 font-bold text-sm  text-white border flex items-center border-emerald-700 bg-emerald-700 rounded-l-lg'><BiDetail className='w-6 h-6 mr-2'/>Details</button>}
        {active!=="details" && <button onClick={()=>setActive("details")} className='px-4 py-2 font-semibold text-sm border flex items-center text-emerald-700 border-emerald-700 rounded-l-lg bg-white hover:bg-emerald-100'><BiDetail className='w-6 h-6 mr-2'/>Details</button>}
        {active==="dataset" && <button className='px-4 py-2 font-bold text-sm  text-white border flex items-center border-emerald-700 bg-emerald-700'><BsQuestionDiamondFill  className='w-6 h-6 mr-2'/>Questions</button>}
        {active!=="dataset" && <button onClick={()=>setActive("dataset")} className='px-4 py-2 font-semibold text-sm border flex items-center text-emerald-700 border-emerald-700 bg-white hover:bg-emerald-100'><BsQuestionDiamondFill  className='w-6 h-6 mr-2'/>Questions</button>}
        {active==="convert" && <button className='px-4 py-2 font-bold text-sm  text-white border flex items-center border-emerald-700 bg-emerald-700 rounded-r-lg'><MdOutlineCreate className='w-6 h-6 mr-2'/>Create</button>}
        {active!=="convert" && <button onClick={()=>setActive("convert")} className='px-4 py-2 font-semibold text-sm border flex items-center text-emerald-700 border-emerald-700 rounded-r-lg bg-white hover:bg-emerald-100'><MdOutlineCreate className='w-6 h-6 mr-2'/>Create</button>}
      </div>
      {active==="details" && <div className='w-full max-w-7xl flex flex-col items-center p-8 md:p-12 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl text-center font-semibold text-emerald-700 mb-4'>Question Set Details</h2>
        <QuestionsetDetailsForm data={data}/>
      </div>}
      {active==="dataset" && <div className='w-full max-w-7xl flex flex-col items-center p-8 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Questions</h2>
        <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
          <button onClick={()=>navigate("/my-questionset-pdf",{state:{questionset_id:questionset_id}})} className="w-full flex flex-col items-center text-lg font-semibold bg-white hover:bg-emerald-700 hover:text-white border border-emerald-700 p-6 rounded"><GrDocumentPdf className='text-5xl mb-2'/><p>Create From PDF</p></button>
          <button onClick={()=>navigate("/my-questionset-csv",{state:{questionset_id:questionset_id}})} className="w-full flex flex-col items-center text-lg font-semibold bg-white hover:bg-emerald-700 hover:text-white border border-emerald-700 p-6 rounded"><GrDocumentCsv className='text-5xl mb-2'/><p>Create From CSV</p></button>
          <button onClick={()=>navigate("/my-questionset-single",{state:{questionset_id:questionset_id}})} className="w-full flex flex-col items-center text-lg font-semibold bg-white hover:bg-emerald-700 hover:text-white border border-emerald-700 p-6 rounded"><IoAddCircleOutline className='text-5xl mb-2' /><p>Manual Add</p></button>
        </div>

        {/* {questions.map(ques=>(<QuestionRow data={ques}/>))} */}
        <div className='w-full p-6 border shadow rounded bg-white'>
          <h2 className='text-xl font-bold text-emerald-700'>Added Questions</h2>
          <p className='text-gray-500 text-sm'>Questions that have been added by you</p>
          {questions.length>0 && <PaginatedQuestions questions={questions}/>}
        </div>
      </div>}
      
      {active==="convert" && <div className='w-full max-w-7xl  flex flex-col items-center p-8 bg-gray-50 border rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Create from question-set</h2>
        <div className='w-full grid grid-cols-2 gap-4'>
          <motion.div onClick={()=>navigate("/my-questionset/create/flashcards")} whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/3JxhSQTB/Ajaira-Stuff-4.png" className='w-16'/>
            <h3 className='text-lg font-semibold'>Create <span className='text-emerald-700'>Flashcards</span></h3>
          </motion.div>
          <motion.div onClick={()=>navigate("/my-questionset/create/quiz")} whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <img src="https://i.postimg.cc/Kz7bVZj9/Ajaira-Stuff-5.png" className='w-16'/>
            <h3 className='text-lg font-semibold'>Create <span className='text-emerald-700'>MCQ Quiz</span></h3>
          </motion.div>
          
          
        </div>
      </div>}
    </div>
  )
}
